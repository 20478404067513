import { store } from '../../_store';
import { aemActions } from '../../_actions';
import { get } from 'lodash/object';
import parse from 'html-react-parser';
import {
    getAEMAssetsUrl,
    getAEMUrl,
    getAEMFlavourResolutionUrl
} from '../../_actions/appConfig.actions';
import { Commons } from '../commons';
import { aemComponents, aemConstants, aemPages, PROFILE_EPOD3, PROFILE_SMARTBOX } from '../../_constants';
import { axiosInstance } from '../api/axiosRequest';

export const propertyTextDefaultEmpty = {
    propertyName: 'text',
    default: '',
}

export const propertyHeadingDefaultEmpty = {
    propertyName: 'heading',
    default: '',
}

export const propertyHeadingDefaultEmptyParsed = {
    propertyName: 'heading',
    parsed: true,
    default: '',
}

export const propertyTextDefaultNull = {
    propertyName: 'text',
    default: null,
}

export const propertyTextDefaultEmptyParsed = {
    propertyName: 'text',
    parsed: true,
    default: '',
}

export const propertyCtaItemsDefaultEmpty = {
    propertyName: 'ctaItems',
    default: '',
    subPropertyNames: ['ctaLabel'],
}
export const propertyCtaItemsDefaultEmptyParsed = {
    propertyName: 'ctaItems',
    default: '',
    parsed: true,
    subPropertyNames: ['ctaLabel'],
}

export const propertyImageSrcDefaultNull = {
    propertyName: 'imageSrc',
    scoped: true,
    default: null,
}


function getLanguageLocalFile(url) {
    //aemHelper.loadJSON('https://d1ve88wqzkjksh.cloudfront.net/global-data/sputnik-site-de.json'),
    //aemHelper.loadJSON('https://d1c3mibp10fz0s.cloudfront.net/global-data/sputnik-site-en.json'),
    //aemHelper.loadJSON('https://dg1511l4mikll.cloudfront.net/global-data/sputnik-site-ko.json'),
    //aemHelper.loadJSON('https://d16pl7ai04eg0t.cloudfront.net/global-data/sputnik-site-ar.json'),
    //aemHelper.loadJSON('https://d1lb3gbxildhg7.cloudfront.net/global-data/sputnik-site-en.json'),

    // take the last three slash separated words like '/ca/dark/fr.json'
    // const languagePath = !!url
    //     ? url.split('/').slice(-3).join('/')
    //     : 'ca/light/en.json';
    const languagePath = (!!url && url.split('global-data/')[1]) || 'ca/light/en.json';

    return require('../../_assets/aem/' + languagePath);
}

function getFlavourLocalFile(){
    return require('../../_assets/flavours/smart_pod_flavours.json');
}

class AEMHelper {

    /**
     * 
     * @param {*} locale 
     * @param {*} src 
     * @returns 
     */
    constructor(locale, src) {
        if (
            locale !== undefined &&
            locale !== null &&
            locale !== ''
        ) {
            this.setLocale(locale);
        }

        if (
            src !== undefined &&
            src !== null &&
            src !== ''
        ) {
            if (Array.isArray(src)) {
                for (let i = 0; i < src.length; i++) {
                    this.loadJSON(src[i]);
                }
            } else {
                this.loadJSON(src);
            }
        }

        if (!locale && !src) {
            log(`[AEMHelper] locale and src NOT SET, allocating empty instance`);
        }
        return this;
    }

    /**
     * Load Language JSON and assign it to a country.
     * A country can have more than one language (e.g. en_CA, fr_CA)
     */
    loadLanguageJSON() {
        const url = getAEMUrl();
        
        if (!!url) {
            if (Commons.isLocalEnvironment()) {
                const file = getLanguageLocalFile(url);
                return this.loadLocalJSON(file, '');
            } else {
                return this.loadJSON(url)
            }
        }
    }

    loadFlavourJSON(){
        // once aem production url is ready, remove all comments in this method

        const url = getAEMFlavourResolutionUrl();
        // const url = "https://d3boq4537ctra2.cloudfront.net/global-data/ca/en/product-data.json"; // production url

        if (!!url) {
            if (Commons.isLocalEnvironment()) {
                const file = getFlavourLocalFile();
                this.loadFlavourLocalJSON(file, '');
                return Promise.resolve();
            }
            return this.loadAEMFlavourJSON(url)
        }
        return Promise.resolve(null);
    }

    /**
     * Load JSON and assign it to a country.
     * A country can have more than one language (e.g. en_CA, fr_CA)
     */
    loadJSON(src) {
        if (/^((http|https):\/\/)/.test(src)) {
            return this.loadRemoteJSON(src);
        } else {
            return this.loadLocalJSON(src);
        }
    }

    loadAEMFlavourJSON(src){
        if (/^((http|https):\/\/)/.test(src)) {
            return this.loadFlavourRemoteJSON(src);
        } else {
            return this.loadFlavourLocalJSON(src);
        }
    }

    /**
     * 
     * @param {*} src 
     */
    loadLocalJSON(src, assetsBaseURL) {
        //Read language and country of the json that is being loaded
        const json = Object.assign({}, src);
        //const aemStore = store.getState().aem;
        //const aemLanguages = get(aemStore, 'languages', []);

        // log(`[AEMHelper][loadLocalJSON] json`, json);

        if (
            json.languages !== undefined &&
            json.languages !== null &&
            json.languages.length > 0
        ) {
            for (let i = 0; i < json.languages.length; i++) {
                store.dispatch(aemActions.addLanguage({
                    language: json.languages[i].language,
                    country: json.siteName.toUpperCase(),
                    pages: json.languages[i].pages,
                    assetsBaseURL: assetsBaseURL
                }));
            }
        }

    }

    loadFlavourLocalJSON(src){
        const flavours = Object.assign({}, src);
        store.dispatch(aemActions.addFlavours(flavours));
    }

    /**
     * 
     * @param {*} src 
     */
    loadRemoteJSON(src) {
        log(`[AEMHelper][loadRemoteJSON] src`, src);

        let config = {
            headers: {
                //'Cache-Control': 'no-cache',
                //'Pragma': 'no-cache',
                //'Expires': '0'
            }
        };

        return new Promise(async (resolve, reject) => {
            await axiosInstance().get(`${src}?timestamp=${new Date().getTime()}`, config)
                .then(response => {
                    
                    if (response.status === 200) {
                        let urlParts = src.split('/');

                        //Load JSON response
                        this.loadLocalJSON(response.data, `${urlParts[0]}//${urlParts[2]}`);

                        resolve(response.data);
                    } else {
                        reject(`Invalid response code ${response.status}`);
                    }

                }, error => {
                    reject(error);
                });
        });
    }

    loadFlavourRemoteJSON(src){
        return new Promise(async (resolve, reject) => {
            await axiosInstance().get(src, {})
                .then(response => {
                    
                    if (response.status === 200) {
                        
                        this.loadFlavourLocalJSON(response.data);
                        resolve(response.data);

                    } else {
                        reject(`Invalid response code ${response.status}`);
                    }

                }, error => {
                    reject(error);
                });
        });
    }

    /**
     * Set current locale used to walk the AEMData.languages[AEMData.locale] object
     * 
     * @param {*} locale 
     */
    setLocale(locale) {
        log(`[AEMHelper][setLocale] locale=${locale}`);

        // AEMData.locale = locale;
        store.dispatch(aemActions.setLocale(locale));
    }

    /**
     * Set current country 
     * 
     * @param {*} country 
     */
    setCurrentCountry(country) {
        log(`[AEMHelper][setCurrentCountry] country=${country}`);

        // AEMData.locale = locale;
        store.dispatch(aemActions.setCurrentCountry(country));
    }

    /**
     * Set current language 
     * 
     * @param {*} language 
     */
    setCurrentLanguage(language) {
        log(`[AEMHelper][setCurrentLanguage] language=${language}`);

        // AEMData.locale = locale;
        store.dispatch(aemActions.setCurrentLanguage(language));
    }

    /**
     * Get assets base URL used to compose image srcs
     * @returns 
     */
    getAssetsBaseURL() {
        log(`[AEMHelper][getAssetsBaseURL] Getting assets base URL`);

        const aemStore = store.getState().aem;
        const aemLanguages = get(aemStore, 'languages', []);

        if (!aemLanguages || aemLanguages.length === 0) {
            log(`[AEMHelper][getPages] invalid languages object!`);
            return null;
        }

        const languageObj = this._getLocalizedObject();
        log('languageObj', languageObj);

        return languageObj.assetsBaseURL;
    }

    getDeviceProfile(deviceType) {
        switch(deviceType) {
            case PROFILE_EPOD3:
                return "_PROSMART";
            case PROFILE_SMARTBOX:
                return "_SMARTBOX";
            default:
                return '';
        }
    }

    /**
     * 
     * @returns 
     */
    getPages() {
        const aemStore = store.getState().aem;
        const aemLanguages = get(aemStore, 'languages', []);

        if (!aemLanguages || aemLanguages.length === 0) {
            log(`[AEMHelper][getPages] invalid languages object!`);
            return this;
        }

        const languageObj = this._getLocalizedObject();
        log('languageObj', languageObj);

        let pages = null;

        if (
            languageObj !== undefined &&
            languageObj !== null
        ) {
            pages = languageObj.pages;
        }
        return pages;
    }

    /**
     * 
     * @param {*} pageName 
     * @returns 
     */
    getPage(pageName) {
        log(`[AEMHelper][getPage] pageName=${pageName}`);

        const aemStore = store.getState().aem;
        const aemLanguages = get(aemStore, 'languages', []);

        if (!aemLanguages || aemLanguages.length === 0) {
            log(`[AEMHelper][getPage] invalid languages object!`);
            return this;
        }

        const languageObj = this._getLocalizedObject();
        log("languageObj", languageObj);
        if (
            languageObj !== undefined &&
            languageObj !== null
        ) {
            let page = languageObj?.pages?.filter(el =>
                el?.pageName === pageName)?.[0] ?? null;
            if (page === null) {
                log(`[AEMHelper][getPage] Cant find page with pageName=${pageName}`);
            } else {
                store.dispatch(aemActions.setPage(page));
            }
        }

        return this;
    }

    getFlavours(){
        const aemStore = store.getState().aem;
        const aemFlavours = get(aemStore, 'flavours', []);

        return Object.keys(aemFlavours).map(
            i => aemFlavours[i]
        );
    }

    getCarouselComponents(pageName, componentNames = []) {
        let carouselComponents = {};
        const page = this.getPage(pageName);
        if (!!page) {
            const components = this.getComponents(pageName, componentNames);
            carouselComponents = components.reduce((acc, component) => {
                const name = component.getRaw('name');
                const items = component.getRaw('items');
                return {
                    ...acc,
                    [name]: {
                        name,
                        items
                    }
                }
            }, {})
        }
        return carouselComponents;
    }

    /**
     * 
     * @param {*} pageName 
     * @param {*} componentNames
     * @returns 
     */
    getComponents(pageName, componentNames = []) {
        log(`[AEMHelper][getComponents] Getting page components for page ${pageName}`);

        const aemStore = store.getState().aem;
        const aemPage = get(aemStore, `pages.${pageName}`, null);

        if (!aemPage) {
            log(`[AEMHelper][getComponents] invalid AEMData.pages object!`);
            return this;
        }

        let components = [];

        if (
            aemPage.globalDataWrapperList &&
            aemPage.globalDataWrapperList.length > 0
        ) {
            for (let i = 0; i < aemPage.globalDataWrapperList.length; i++) {
                let component = aemPage.globalDataWrapperList[i]
                    .globalDataMap['global-data'];
                if (null != component) {
                    for (let c = 0; c < component.length; c++) {
                        if (Object.keys(component[c]).length > 0) {
                            for (let key in component[c]) {
                                if (
                                    component[c].hasOwnProperty(key) && (
                                        componentNames.length === 0 || componentNames.includes(key)
                                    )
                                ) {
                                    components = [
                                        ...components,
                                        new AEMComponent({
                                            name: key,
                                            ...component[c][key]
                                        })
                                    ];
                                }
                            }
                        }
                    }
                }
            }
        }
        return components;
    }

    /**
     * 
     * @param {*} pageName 
     * @param {*} componentName 
     * @returns 
     */
    getComponent(pageName, componentName) {
        log(`[AEMHelper][getComponent] pageName=${pageName}`);
        log(`[AEMHelper][getComponent] componentName=${componentName}`);

        const aemStore = store.getState().aem;
        const aemPage = get(aemStore, `pages.${pageName}`, null);

        console.debug('[AEMComponent] - getComponent aemPage', aemPage);

        if (!aemPage) {
            log(`[AEMHelper][getComponent] invalid AEMData.pages object!`);
            return new AEMComponent();
        }

        if (
            aemPage.globalDataWrapperList &&
            aemPage.globalDataWrapperList.length > 0
        ) {
            for (let i = 0; i < aemPage.globalDataWrapperList.length; i++) {
                let component = aemPage.globalDataWrapperList[i]
                    .globalDataMap['global-data'];
                if (null != component) {
                    for (let c = 0; c < component.length; c++) {
                        if (Object.keys(component[c]).length > 0) {
                            for (let key in component[c]) {
                                if (
                                    component[c].hasOwnProperty(key) &&
                                    key === componentName
                                ) {
                                    return new AEMComponent({
                                        name: componentName,
                                        ...component[c][key]
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        return null;
    }

    /**
     * 
     * @param {*} pageName 
     * @param {*} componentName 
     * @returns 
     */
    getCarouselUrls(pageName, componentName, imageSrcProperty = 'desktop') {
        const carousel = this.getComponent(pageName, componentName);

        console.debug('[AEMComponent] - Carousel', carousel);

        if (carousel) {
            let urls = [];

            if (carousel?.getRaw('items')?.length) {
                for (let i = 0; i < carousel.getRaw('items')?.length; i++) {
                    let item = carousel.getRaw('items')?.[i];

                    let assetUrl = item?.image?.imageSrc[imageSrcProperty]?.src ?? null;

                    if (assetUrl) {
                        log(`[AEMComponent][downloadAssets] Downloading ${getAEMAssetsUrl()}${assetUrl}`);
                        urls.push(`${getAEMAssetsUrl()}${assetUrl}`);
                    }
                }
            }
            return urls;
        } else {
            return [];
        }

    }

    getPageName(pageName, deviceType) {
        console.debug('[AEMComponent] - getAEMPageFor', deviceType, pageName, aemPages[pageName + this.getDeviceProfile(deviceType)]);

        if (deviceType) {
            return  aemPages[pageName + this.getDeviceProfile(deviceType)];
        }

        return aemPages[pageName];
    }

    getCarouselUrlsFor(deviceType, pageName) {
        console.debug('[AEMComponent] - getCarouselUrlsFor', deviceType, pageName, aemPages[pageName + this.getDeviceProfile(deviceType)]);
        const page = aemPages[pageName + this.getDeviceProfile(deviceType)];
        this.getPage(page);

        return this.getCarouselUrls(
            aemPages[pageName + this.getDeviceProfile(deviceType)],
            "carousel"
        );
    }

    getDictionary(pageName, properties = {}) {

        const page = this.getPage(pageName);

        const dictionary = Object.keys(properties).reduce((acc, originalPropertyKey) => {
            const property = properties[originalPropertyKey] || {
                propertyName: 'text',
                parsed: false,
                default: null
            };

            const propertyKey = property.rename ?? originalPropertyKey;

            let newValues = {
                [propertyKey]: property.default
            };

            //console.log("properties", properties);
            //console.log("pageName", pageName);

            if (!!page) {
                const component = this.getComponent(pageName, originalPropertyKey);
                //console.log("component", component);
                const rawValue = component?.getRaw(property.propertyName, property.default) ?? property.default;
                //console.log("rawValue", rawValue);
                if (!!rawValue) {
                    if (Array.isArray(rawValue)) {
                        if (property.subPropertyNames && property.subPropertyNames.length > 0) {
                            newValues = rawValue.reduce((acc1, subProperty, index) => {
                                return {
                                    ...acc1,
                                    ...property.subPropertyNames.reduce((acc2, subPropertyName) => {
                                        return {
                                            ...acc2,
                                            [`${propertyKey}_${index}_${subPropertyName}`]: subProperty[subPropertyName] || property.default
                                        }
                                    }, {})
                                }
                            }, {});
                        } else {
                            newValues = {};
                        }
                    } else if (property.parsed) {
                        const readyToParseValue = rawValue.replaceAll("&gt;", ">").replaceAll("&lt;", "<")
                        newValues[propertyKey] = parse(readyToParseValue);
                    } else {
                        newValues[propertyKey] = rawValue;
                    }
                }
            }

            return {
                ...acc,
                ...newValues,
            }
        }, {});

        log("[DICTIONARY]", dictionary);
        return dictionary;
    }

    /**
     * 
     * @returns 
     */
    _getLocalizedObject() {
        const aemStore = store.getState().aem;
        const aemLanguages = get(aemStore, 'languages', []);
        const aemLocale = get(aemStore, 'locale', null);

        log(`[AEMHelper][_getLocalizedObject] Getting localized object for ${aemLocale}`);

        try {
            let localeParts = aemLocale.split('_');
            log(`[AEMHelper][_getLocalizedObject] localeParts`, localeParts);

            let obj = aemLanguages
                .filter(el =>
                    el.language === localeParts[0] /*&& el.country === localeParts[1]*/);

            if (obj === undefined || obj.length === 0) {
                log(`[AEMHelper][_getLocalizedObject] cant find match for ${localeParts[0]}_${localeParts[1]} pair`);
                return null;
            }

            log(`[AEMHelper][_getLocalizedObject] obj`, obj[0]);
            return obj[0];

        } catch (error) {
            log(`[AEMHelper][_getLocalizedObject] error`, error);
            return null;
        }
    }
}

class AEMComponent {

    constructor(component) {
        this.component = component || null;
    }

    /**
     * 
     * @returns 
     */
    getText() {
        if (!this.component) {
            log(`[AEMComponent][getText] invalid component object!`);
            return null;
        }

        let value = get(this.component, 'text', null);

        log(`[AEMComponent][getText] value=${value}`);
        return value;
    }

    /**
     * 
     * @param {*} property 
     * @param {*} defaultValue 
     * @returns 
     */
    getRaw(property, defaultValue) {
        log(`[AEMComponent][getRaw] property=${property}`);

        if (!this.component) {
            log(`[AEMComponent][getRaw] invalid component object!`);
            return null;
        }

        let value = get(this.component, property, defaultValue || null);

        log(`[AEMComponent][getRaw] value=${value}`);
        return value;
    }
}


function log() {
    //const aemStore = store.getState().aem;
    //const aemDebug = get(aemStore, 'debug', false);

    // if(aemDebug) {
    //console.log.apply(console, arguments);
    // }
}

export { AEMHelper };