import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from '../_helpers/history';
import { buildURI } from '../_helpers/navigation';
import Modal from 'react-modal';
import sdk from '../_vendors/nodes';
import { LastLocationProvider } from 'react-router-last-location';
import "react-spring-bottom-sheet/dist/style.css";
import 'react-circular-progressbar/dist/styles.css';
import {
    RootView,
    SoftAgeVerification,
    ClearData,
    Test,
    CountrySelector,
    OnboardingBenefits,
    UnderAge,
    Signup,
    ResetPassword,
    TermsConditions,
    TermsConditionsList,
    TermsAndConditionsAndPrivacyPolicy,
    NotificationPreferencesIntro,
    NotificationPreferencesOptions,
    AgeVerificationFailure,
    Dashboard,
    ManageNotifications,
    ManageDevices,
    BatterySettingsTutorial,
    VaultSetup,
    VaultRestore,
    Pairing,
    CloudControl,
    CloudControlTutorial,
    FindMyVapeTutorial,
    FindMyVapeOptin,
    FindMyVapeOptinInstructions,
    DeviceSettings,
    BatterySettings,
    AdvancedSettings,
    YourPreferences,
    ManageAccount,
    EditDetails,
    ContactUs,
    UnlockTutorial,
    PastOrders,
    AccessDenied,
    ExtPage,
    MaintenancePage,
    BlogArticles,
    DeviceFaq,
    PrivacyPolicy,
    CookiePolicy,
    ConditionsOfSale,
    SubscriptionTermsAndConditions,
    LocationServices,
    SelectDevice,
    LoyaltyOptin,
    LoyaltyDashboard,
    LockSwitchTutorial,
    PublicPage,
    DeviceName,
    PodTracker,
    PodTrackerOptinInstructions,
    PodTrackerTutorial
} from '../Views';
import '../_assets/scss/core.scss';
import { 
    ProtectedRoute, 
    ErrorAlert, 
    PublicRoute, 
    buildPublicRoutePath, 
    NuviuVersionAlert
} from '../_components';
import { ANIMATION_DURATION, crmUserSettings, routingConstants, servicesConstants } from '../_constants';
import { store } from '../_store';
import { deviceActions, getStorageItem, getUserData, setStorageItem, userActions } from '../_actions';
import { 
    Transition, 
    TransitionGroup 
} from 'react-transition-group';
import { play, exit } from '../_helpers/navigation';
import { getLocale, getTenantId, isSupportedService, setAppConfig, setLocale } from '../_actions/appConfig.actions';
import { Login } from '../Views/Login/Login';
import { AEMHelper } from '../_helpers/aem/aemhelper';
import { FindMyVape } from '../Views/FindMyVape';
import {
    getDeviceInstanceFromSN, 
    getDeviceThingFromSN, 
    loadDeviceCharacteristics, 
    setDeviceConnectionStateChanged,
    initSubscriber,
    updateDevicesPosition
} from '../_helpers/device';
import { debug } from '../_helpers/debug';
import { DeviceModel } from '../_models';
import { PageContainer } from '../_components/PageContainer';
import environmentConstants from '../_constants/environment/environment.constants';
import { Notifications } from '../_helpers/notifications';
import { AlertAppVersion } from '../_components/AlertAppVersion/AlertAppVersion';
import { Debug } from '../Views/Debug';
import { PushNotificationLandingPage } from '../Views/PushNotificationLandingPage';
import { commonsServices } from '../_services';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { UsageTracker, UsageTrackerOptin, UsageTrackerOptinInstructions, UsageTrackerTutorial } from '../Views/UsageTracker';
import initialize from '../_helpers/utils/initializeDb';
import { getUsageTrackerOptInDate, sendRawData } from "../_helpers/usage-tracker";
import moment from 'moment';
import { HardAgeVerification } from '../Views/HardAgeVerification/HardAgeVerification';
import { StoreLocator } from '../Views/StoreLocator/StoreLocator';
import { configureQualtrics } from "../_vendors/qualtrics";
import { autoReconnectAllDevices } from '@nodes-projects/bat-sdk-web';
import { configSalesforce } from '../_helpers/salesforcce';
import { FAQ } from '../Views/Faq/Faq';
import BrowserHelper from '../_helpers/browser/browser.helper';
import { ToastContainer, cssTransition, toast } from 'react-toastify';
import { sendCalculatedData } from '../_helpers/utils';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.aem = new AEMHelper(getLocale());

    this.lastPathname = false

    this.level1 = [
        routingConstants.DASHBOARD
    ]

    this.level2 = [
        routingConstants.MANAGE_DEVICES,
        routingConstants.MANAGE_NOTIFICATIONS,
        routingConstants.YOUR_PREFERENCES,
        routingConstants.MANAGE_ACCOUNT,
        routingConstants.PREVIOUS_ORDERS,
        routingConstants.TERMS_AND_POLICY,
        routingConstants.CONTACT,
        routingConstants.TUTORIALS,
        routingConstants.UNLOCK_TUTORIAL,
        routingConstants.DEVICE_FAQ,
        routingConstants.STORE_LOCATOR,
        routingConstants.USAGE_TRACKER,
        routingConstants.USAGE_TRACKER_OPTIN,
        routingConstants.USAGE_TRACKER_TUTORIAL,
        routingConstants.POD_TRACKER_TUTORIAL,
        routingConstants.FIND_MY_VAPE,
        routingConstants.FIND_MY_VAPE_OPTIN,
        routingConstants.FIND_MY_VAPE_TUTORIAL,
        routingConstants.CLOUD_CONTROL,
        routingConstants.CLOUD_CONTROL_TUTORIAL,
        routingConstants.BATTERY_SETTINGS,
        routingConstants.BATTERY_SETTINGS_TUTORIAL,
        routingConstants.SELECT_DEVICE,
        routingConstants.PRIVACY_POLICY,
        routingConstants.TERMS_AND_CONDITIONS_LIST,
        routingConstants.LOYALTY_OPTIN,
        routingConstants.LOYALTY_DASHBOARD,
        routingConstants.LOCK_SWITCH_TUTORIAL
    ]

    this.level3 = [
        routingConstants.DEVICE_SETTINGS,
        routingConstants.DEVICE_NAME,
        routingConstants.ADVANCED_SETTINGS,
        routingConstants.PAIR_DEVICE,
        routingConstants.USAGE_TRACKER_OPTIN_INSTR,
        routingConstants.FIND_MY_VAPE_OPTIN_INSTR,
        routingConstants.EDIT_ACCOUNT,
        routingConstants.LOCATION_SERVICES
    ]
}

  componentDidMount() {
    //this is to manage encoded # in the url if we are on the dashboard page (avoid conflicts with utm_data)
    const currentUrl = window.location.pathname;

    if (currentUrl.includes('dashboard%23')) {
      window.location.href = currentUrl.replace('dashboard%23', 'dashboard#')
      return false
    }

    Modal.setAppElement(document.getElementById("root"));


    //disconnect all the devices before window refresh
    this.cleanDeviceData();

    //prevent IOS swipe-to-go-back gesture
    const element = document.querySelector("body");
    element.addEventListener("touchstart", (e) => {
      // is not near edge of view, exit
      if (e?.touches[0]?.pageX) {
        if (
          e.touches[0].pageX > 10 &&
          e.touches[0].pageX < window.innerWidth - 10
        )
          return;
      }
      // prevent swipe to navigate gesture
      e.preventDefault();
    });

    this.parentNode = React.createRef();

    /* Handle change visibility status */
    window.document.addEventListener("visibilitychange", () =>
      this.handleAppVisibilityChange()
    );

    window.onload = () => {
      /* Reload translations and flavours */
      Promise.all([
        this.aem.loadLanguageJSON(),
        this.aem.loadFlavourJSON().catch(error => {
          console.error("Error loading Flavour JSON:", error);
          return null; // fallback value
        })
      ]);
      //reload tenant-configuration
      if (!isNil(getTenantId())) {
        commonsServices
          .getCountryConfig(getTenantId())
          .then((countryConfig) => {
            if (countryConfig?.data?.data) {
              const currentLocale = getLocale();
              setAppConfig(countryConfig.data.data);
              setLocale(currentLocale);
            }
          });
      }

      if(!isNil(getUserData()?.id)){
        store.dispatch(
          userActions.setUserSettings(
            crmUserSettings.LAST_OPENEDTHEAPP,
            (new Date()).toISOString()
          )
        )
        console.log("crm setted")

        //autoreconnect devices
        this.autoreconnect()
      }
    };

    window.onerror = (msg, url, lineNo, columnNo, error) => {
      debug(
        `[ERROR] msg ${msg}: ${JSON.stringify(error) ?? ""} ${
          error?.stack ?? ""
        }`
      );
      return false;
    };

    if (isSupportedService(servicesConstants.QUALTRICS)) {
      configureQualtrics();
      // qualtricsRunner()
    }

    configSalesforce()

    if (isSupportedService(servicesConstants.USAGE_TRACKER)) {
      // Initialize RXDB
      initialize();
    }

    //create list of devices to reconnect
    const devicesToReconnect = []
    let devicesReducer = store.getState().deviceReducer?.devices;
    devicesReducer.forEach((deviceReducer) => {
      const name = !isEmpty(deviceReducer?.deviceInfo?.advertisingName) ? deviceReducer?.deviceInfo?.advertisingName : deviceReducer?.deviceInfo?.deviceName
      // if (name) {
      devicesToReconnect.push(name)
      // } else {
      //   devicesToReconnect.push(deviceReducer?.deviceCustomName)
      // }
    })

    if (BrowserHelper.isBLEBrowser()) {
      if(!isNil(devicesToReconnect) && devicesToReconnect.length > 0){
        autoReconnectAllDevices(devicesToReconnect, (device) => device.setConnectionStateChanged((connectionState) => setDeviceConnectionStateChanged(connectionState, device, true)))
      }
    }

    //update devices position
    updateDevicesPosition()

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js').then((registration) => {
          console.log('SW registered: ', registration);
        }).catch((registrationError) => {
          console.log('SW registration failed: ', registrationError);
        });
      });
    }

    //Nuviu enhancements - add application
    const now = new Date().getTime()
    const firstPairing = getStorageItem('firstPairing')
    const applicationPrompt = getStorageItem('applicationPrompt')
    //check if at least 5 minutes have passed
    if (!applicationPrompt && firstPairing && (firstPairing + (5 * 60000)) <= now) {
      if (navigator.application?.prompt) {
        navigator.application.prompt().then(status => {
          if (status === 'denied' || status === 'accepted' ) {
            setStorageItem('applicationPrompt', true)
            if (status === 'accepted') {
              setStorageItem('applicationAdded', true)
              setStorageItem('widgetAddReminder', now + (5 * 60000))
            }
          } else if (status === 'remindMeLater') {
            //ask again the next day
            const nextCheck = now + (24 * 60 * 60000)
            setStorageItem('firstPairing', nextCheck)
          }
        })
      }
    }

    //Nuviu enhancements - widget tutorial
    const applicationAdded = getStorageItem('applicationAdded')
    const widgetTutorial = getStorageItem('widgetTutorial')
    const widgetAddReminder = getStorageItem('widgetAddReminder')
    if (!widgetTutorial && applicationAdded && widgetAddReminder <= now) {
      if (navigator.osWidget?.promptTutorial) {
        navigator.osWidget.promptTutorial().then(status => {
          if (status === 'denied' || status === 'accepted' ) {
            setStorageItem('widgetTutorial', true)
          } else if (status === 'remindMeLater') {
            //ask again the next day
            const nextCheck = now + (24 * 60 * 60000)
            setStorageItem('widgetAddReminder', nextCheck)
          }
        })
      }
    }
      
  }

  handleAppVisibilityChange() {
    //console.log("[handleAppVisibilityChange] document.visibilityState", document.visibilityState);

    if (document.visibilityState === "visible") {
      if (getUserData()?.id) {
        //check if user is logged
        Notifications.handleRemoteNotifications();

        this.autoreconnect()
      }

      if (isSupportedService(servicesConstants.USAGE_TRACKER)) {
        const usageTrackerOptinDate = getUsageTrackerOptInDate();

        if (moment().isSameOrAfter(usageTrackerOptinDate)) {
          sendCalculatedData();

          if (isSupportedService(servicesConstants.USAGE_TRACKER_RAW)) {
            sendRawData();
          }
        }
      }
    } else {
      if (isSupportedService(servicesConstants.USAGE_TRACKER)) {
      const devices = store.getState().deviceReducer.devices;

        devices.forEach(async (device) => {
          const deviceInstance = await getDeviceInstanceFromSN(
            device.serialNumber
          );
          if (deviceInstance) {
            if (
              sdk[device.deviceType].ConnectionState.Synchronized ===
                deviceInstance._connectionState ||
              sdk[device.deviceType].ConnectionState.Connected ===
                deviceInstance._connectionState
            ) {
              deviceInstance.unsubscribeFromPuffRecord()
            }
          }
        })
      }
    }
  }

  /**
   * When browser goes from a "not visible" state to the visible one
   * we update the app with the devices' current characteristics values, to
   * mirror correctly the current statuses
   */
  async autoreconnect() {
    if (BrowserHelper.isBLEBrowser()) {
      const devices = store.getState().deviceReducer.devices;

      const devicesToReconnect = []

      for (const device of devices) {
        const deviceInstance = await getDeviceInstanceFromSN(
          device.serialNumber
        );
        const deviceThing = getDeviceThingFromSN(device.serialNumber);
        // console.log("[handleAppVisibilityChange] deviceInstance", deviceInstance);
        // console.log("[handleAppVisibilityChange] deviceThing", deviceThing);

        let callback = null;
        let devicePayload = {
          ...device,
        };

        if (deviceInstance) {
          //update redux values
          devicePayload = {
            ...devicePayload,
            connectionState: deviceInstance._connectionState,
          };
          devicePayload = new DeviceModel(devicePayload);

          //set callback to load characteristics only if device is connected/syncronized

          if (
            sdk[device.deviceType].ConnectionState.Synchronized ===
              deviceInstance._connectionState ||
            sdk[device.deviceType].ConnectionState.Connected ===
              deviceInstance._connectionState
          ) {
            callback = () => {
              loadDeviceCharacteristics(
                deviceInstance,
                device.serialNumber,
                deviceThing.uuid
              );

              initSubscriber(deviceInstance, device)
            }
          }
        } else {
          //update redux values
          devicePayload = {
            ...devicePayload,
            connectionState:
              sdk[device.deviceType].ConnectionState.Disconnected,
          };
          devicePayload = new DeviceModel(devicePayload);
        }

        store.dispatch(
          deviceActions.addOrUpdateDevice(
            devicePayload.toObj(),
            false,
            callback
          )
        );

        if (isNil(deviceInstance)){
          const name = !isEmpty(device?.deviceInfo?.advertisingName) ? device?.deviceInfo?.advertisingName : device?.deviceInfo?.deviceName;
          devicesToReconnect.push(name);
        }

      }

      if(!isNil(devicesToReconnect) && devicesToReconnect.length > 0){
        autoReconnectAllDevices(devicesToReconnect, (device) => device.setConnectionStateChanged((connectionState) => setDeviceConnectionStateChanged(connectionState, device, true)))
      }
    }
  }

  cleanDeviceData() {

    let devicesReducer = store.getState().deviceReducer?.devices;
    devicesReducer.forEach((deviceReducer) => {
        if (null !== deviceReducer && deviceReducer !== undefined) {
            let devicePayload = {
                ...deviceReducer,
                connectionState: sdk[deviceReducer.deviceType].ConnectionState.Disconnecting,
                batteryInfo: null,
                // lockInfo: null,
                cloudInfo: null,
                selected: false,
                isBlinking: false,
                ledInfo: null,
                isSmartPod: null
            }

            store.dispatch(deviceActions.addOrUpdateDevice(new DeviceModel(devicePayload)));
            /*
            if(scheduler.getById(jobId) != null && scheduler.getById(jobId) !== undefined){
                scheduler.stopById(`fmv-location-retrieval-job-${deviceReducer.serialNumber}`);
                scheduler.removeById(`fmv-location-retrieval-job-${deviceReducer.serialNumber}`);
            }
            */
            // Remove from global var
            //const filteredItems = window.deviceList?.filter(device => device.serialNumber !== serialnumber);
            window.deviceList = [];
        }
    });
  }


  onPlay(pathname, node, appears, parentNode, state) {
      if (!state?.type) {
          if (this.level2.includes(pathname) && this.level3.includes(this.lastPathname)) {
              if (!state) {
                  state = {type: 'slide-left'}
              } else {
                  state.type = 'slide-left'
              }
          } else if (this.level2.includes(pathname)) {
              if (!state) {
                  state = {type: 'pop'}
              } else {
                  state.type = 'pop'
              }
          } else if (this.level3.includes(pathname)) {
              if (!state) {
                  state = {type: 'slide'}
              } else {
                  state.type = 'slide'
              }
          }
      }

      play(pathname, node, appears, parentNode, state)

      this.lastPathname = pathname
  }

    onExit(pathname, node, appears, parentNode, state) {
        const nextPath = history.location.pathname

        if (history.location.state?.type === 'slide') {
            if (!state) {
                state = {type: 'slide-left'}
            } else {
                state.type = 'slide-left'
            }
        } else if (history.location.state?.type === 'slide-left') {
            if (!state) {
                state = {type: 'slide'}
            } else {
                state.type = 'slide'
            }
        } else if (this.level2.includes(pathname) && this.level3.includes(nextPath)) {
            if (!state) {
                state = {type: 'slide-left'}
            } else {
                state.type = 'slide-left'
            }
        } else if (this.level1.includes(nextPath)) {
            if (!state) {
                state = {type: 'pop'}
            } else {
                state.type = 'pop'
            }
        } else if (this.level2.includes(pathname)) {
            if (!state) {
                state = {type: 'pop'}
            } else {
                state.type = 'pop'
            }
        } else if (this.level3.includes(pathname)) {
            if (!state) {
                state = {type: 'slide'}
            } else {
                state.type = 'slide'
            }
        }

        exit(pathname, node, appears, parentNode, state)
    }

    render() {
        return (
            <React.Fragment>
                <Router history={history} basename={process.env.REACT_APP_DEPLOYMENT_PATH}>

                    <LastLocationProvider>
                        <PageContainer ref={this.parentNode}>
                            <NuviuVersionAlert />
                            <ErrorAlert />
                            <AlertAppVersion />
                            <Route render={({ location, history, match }) => {
                              const { pathname, key, state } = location;

                              const duration = ANIMATION_DURATION

                              return (
                                <>
                                  <ToastContainer
                                    position='top-left'
                                    newestOnTop={true}
                                    closeOnClick
                                    rtl={false}
                                    hideProgressBar
                                    limit={3}
                                    transition={cssTransition({
                                        enter: 'animate__slide-enter--custom',
                                        exit: 'animate__slide-exit--custom',
                                        collapse: false
                                    })}
                                    className={`notifications-container-dashboard`}
                                    bodyClassName='notification-body'
                                    pauseOnHover
                                    pauseOnFocusLoss
                                  />
                                  <TransitionGroup component={null}>
                                        <Transition
                                            key={key}
                                            appear={true}
                                            onEnter={(node, appears) => this.onPlay(pathname, node, appears, this.parentNode, state)}
                                            onExit={(node, appears) => this.onExit(pathname, node, appears, this.parentNode, state)}
                                            timeout={{ enter: duration, exit: duration }}
                                        >

                                            <Switch location={location}>

                                                <ProtectedRoute exact path={buildURI(routingConstants.ROOT_VIEW)} component={RootView} />
                                                <ProtectedRoute path={buildURI(routingConstants.COUNTRY_SELECTOR)} component={CountrySelector} />
                                                <ProtectedRoute path={buildURI(routingConstants.SOFT_AGE_VERIFICATION)} component={SoftAgeVerification} />
                                                <ProtectedRoute path={buildURI(routingConstants.HARD_AGE_VERIFICATION)} component={HardAgeVerification} />
                                                <ProtectedRoute path={buildURI(routingConstants.BENEFITS)} component={OnboardingBenefits} />
                                                <ProtectedRoute path={buildURI(routingConstants.UNDERAGE)} component={UnderAge} />
                                                <ProtectedRoute path={buildURI(routingConstants.SIGN_UP)} component={Signup} />
                                                <ProtectedRoute path={buildURI(routingConstants.SIGNUP_FAILED)} component={AgeVerificationFailure} />
                                                <ProtectedRoute path={buildURI(routingConstants.LOGIN)} component={Login} />
                                                <ProtectedRoute path={buildURI(routingConstants.RESET_PASSWORD)} component={ResetPassword} />
                                                <ProtectedRoute path={buildURI(routingConstants.TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY)} component={TermsAndConditionsAndPrivacyPolicy} />
                                                <ProtectedRoute path={buildURI(routingConstants.TERMS_AND_CONDITIONS)} component={TermsConditions} />
                                                <ProtectedRoute path={buildURI(routingConstants.TERMS_AND_CONDITIONS_LIST)} component={TermsConditionsList} />
                                                <ProtectedRoute path={buildURI(routingConstants.NOTIFICATION_INTRO)} component={NotificationPreferencesIntro} />
                                                <ProtectedRoute path={buildURI(routingConstants.NOTIFICATION_PREFERENCES)} component={NotificationPreferencesOptions} />
                                                <ProtectedRoute path={buildURI(routingConstants.DASHBOARD)} component={Dashboard} />
                                                <ProtectedRoute path={buildURI(routingConstants.VAULT)} component={VaultSetup} />
                                                <ProtectedRoute path={buildURI(routingConstants.VAULT_RESTORE)} component={VaultRestore} />
                                                <ProtectedRoute path={buildURI(routingConstants.SELECT_DEVICE)} component={SelectDevice} />
                                                <ProtectedRoute path={buildURI(routingConstants.PAIR_DEVICE)} component={Pairing} />

                                                <ProtectedRoute path={buildURI(routingConstants.POD_TRACKER)} component={PodTracker} />
                                                <ProtectedRoute path={buildURI(routingConstants.POD_TRACKER_TUTORIAL)} component={PodTrackerTutorial} />
                                                <ProtectedRoute path={buildURI(routingConstants.POD_TRACKER_OPTIN_INSTR)} component={PodTrackerOptinInstructions} />

                                                <ProtectedRoute path={buildURI(routingConstants.LOCK_SWITCH_TUTORIAL)} component={LockSwitchTutorial} />

                                                <ProtectedRoute path={buildURI(routingConstants.BATTERY_SETTINGS)} component={BatterySettings} />
                                                <ProtectedRoute path={buildURI(routingConstants.BATTERY_SETTINGS_TUTORIAL)} component={BatterySettingsTutorial} />

                                                <ProtectedRoute path={buildURI(routingConstants.CLOUD_CONTROL)} component={CloudControl} />
                                                <ProtectedRoute path={buildURI(routingConstants.CLOUD_CONTROL_TUTORIAL)} component={CloudControlTutorial} />

                                                <ProtectedRoute path={buildURI(routingConstants.FIND_MY_VAPE)} component={FindMyVape} />
                                                <ProtectedRoute path={buildURI(routingConstants.FIND_MY_VAPE_TUTORIAL)} component={FindMyVapeTutorial} />
                                                <ProtectedRoute path={buildURI(routingConstants.FIND_MY_VAPE_OPTIN)} component={FindMyVapeOptin} />
                                                <ProtectedRoute path={buildURI(routingConstants.FIND_MY_VAPE_OPTIN_INSTR)} component={FindMyVapeOptinInstructions} />

                                                <ProtectedRoute path={buildURI(routingConstants.USAGE_TRACKER)} component={UsageTracker} />
                                                <ProtectedRoute path={buildURI(routingConstants.USAGE_TRACKER_OPTIN)} component={UsageTrackerOptin} />
                                                <ProtectedRoute path={buildURI(routingConstants.USAGE_TRACKER_TUTORIAL)} component={UsageTrackerTutorial} />
                                                <ProtectedRoute path={buildURI(routingConstants.USAGE_TRACKER_OPTIN_INSTR)} component={UsageTrackerOptinInstructions} />

                                                <ProtectedRoute path={buildURI(routingConstants.DEVICE_SETTINGS)} component={DeviceSettings} />
                                                <ProtectedRoute path={buildURI(routingConstants.DEVICE_NAME)} component={DeviceName} />
                                                <ProtectedRoute path={buildURI(routingConstants.ADVANCED_SETTINGS)} component={AdvancedSettings} />
                                                <ProtectedRoute path={buildURI(routingConstants.LOYALTY_OPTIN)} component={LoyaltyOptin} />
                                                <ProtectedRoute path={buildURI(routingConstants.LOYALTY_DASHBOARD)} component={LoyaltyDashboard} />
                                                <ProtectedRoute path={buildURI(routingConstants.MANAGE_NOTIFICATIONS)} component={ManageNotifications} />
                                                <ProtectedRoute path={buildURI(routingConstants.MANAGE_DEVICES)} component={ManageDevices} />
                                                <ProtectedRoute path={buildURI(routingConstants.YOUR_PREFERENCES)} component={YourPreferences} />
                                                <ProtectedRoute path={buildURI(routingConstants.MANAGE_ACCOUNT)} component={ManageAccount} />
                                                <ProtectedRoute path={buildURI(routingConstants.EDIT_ACCOUNT)} component={EditDetails} />
                                                {/* <ProtectedRoute path={buildURI(routingConstants.TUTORIALS)} component={FeaturesTutorial} /> */}
                                                <ProtectedRoute path={buildURI(routingConstants.CONTACT)} component={ContactUs} />
                                                <ProtectedRoute path={buildURI(routingConstants.UNLOCK_TUTORIAL)} component={UnlockTutorial} />
                                                <ProtectedRoute path={buildURI(routingConstants.PREVIOUS_ORDERS)} component={PastOrders} />

                                                <ProtectedRoute path={buildURI(routingConstants.BLOG_ARTICLES)} component={BlogArticles} />

                                                <ProtectedRoute path={buildURI(routingConstants.EXT_PAGE)} component={ExtPage} />
                                                <Route path={buildURI(routingConstants.MAINTENANCE_PAGE)} component={MaintenancePage} />
                                                <ProtectedRoute path={buildURI(routingConstants.DEVICE_FAQ)} component={DeviceFaq} />
                                                <ProtectedRoute path={buildURI(routingConstants.STORE_LOCATOR)} component={StoreLocator} />

                                                <ProtectedRoute path={buildURI(routingConstants.PRIVACY_POLICY)} component={PrivacyPolicy} />
                                                <ProtectedRoute path={buildURI(routingConstants.COOKIE_POLICY)} component={CookiePolicy} />
                                                <ProtectedRoute path={buildURI(routingConstants.CONDITIONS_OF_SALE)} component={ConditionsOfSale} />
                                                <ProtectedRoute path={buildURI(routingConstants.SUBSCRIPTION_TERMS_AND_CONDITIONS)} component={SubscriptionTermsAndConditions} />

                                                <ProtectedRoute path={buildURI(routingConstants.LOCATION_SERVICES)} component={LocationServices} />

                                                {/* Routes used from external services, like android app */}.
                                                <PublicRoute path={buildPublicRoutePath('/android-tac')} component={TermsConditionsList} />
                                                <PublicRoute path={buildPublicRoutePath('/android-pp')} component={PrivacyPolicy} />

                                                <Route path={buildURI('/:lang?/:os?/:market?/faq')} component={FAQ}/>
                                                <Route path={buildURI(routingConstants.PUSH_NOTIFICATION_LANDING_PAGE)} component={PushNotificationLandingPage} />

                                                <Route path={buildURI(routingConstants.ACCESS_DENIED)} component={AccessDenied} />
                                                <Route path={buildURI(routingConstants.GPS_PP)} component={PublicPage} />
                                                <Route path={buildURI(routingConstants.ACCOUNT_DELETION)} component={PublicPage} />
                                                {process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION && <Route path={buildURI('/clear-data')} component={ClearData} />}
                                                {process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION && <Route path={buildURI('/debug')} component={Debug} />}
                                                {process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION && <Route path={buildURI('/test')} component={Test} />}
                                            </Switch>
                                        </Transition>
                                    </TransitionGroup>
                                  </>
                                )
                            }} />
                        </PageContainer>
                    </LastLocationProvider>
                </Router>
            </React.Fragment>
        );
    }
}

export { App };
