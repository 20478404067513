import React, { useEffect } from "react";
import { WebView } from "../../_components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  commonsActions,
  setAcceptedTermsAndConditions,
  setAccessToken,
  setDoneVault,
  setRegistration,
  setUserData,
  setUserEverLogged,
  userActions,
} from "../../_actions";
import { useDispatch } from "react-redux";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import {
  AnalyticsEvents,
  crmUserSettings,
  routingConstants,
  servicesConstants,
} from "../../_constants";
import {
  getLocale,
  getSupportedServices,
  getTenantId,
  setAppConfig,
  setLocale,
} from "../../_actions/appConfig.actions";
import { Tenants } from "../../_helpers/tenants";
import { store } from "../../_store";
import { buildURI } from "../../_helpers/navigation";

export const LoginWebview = ({ url }) => {
  const history = useHistory();
  const baseUrl = url.split("/")[2];

  const dispatch = useDispatch();

  useEffect(() => {
    const handleIframeMessage = (event) => {
      // Ensure the message is coming from the trusted domain
      if (event.origin === "https://" + baseUrl) {
        if (event.data.type === "redirect" && event.data.path === "/success") {
          // Redirect to /login when the iframe path is /success
          setAccessToken(event.data.token);
          getUserData(event.data.userId);
          setRegistration({});
        }
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [history]);

  const getUserData = (userId) => {
    dispatch(
      userActions.getUserInfo(userId, getUserInfoSuccess, getUserInfoError)
    );
  };

  const getUserInfoSuccess = (payload) => {
    console.debug("NEW_LOGIN", payload);

    const userData = payload.user;

    const newUserData = {
      ...userData,
    };

    if (userData?.externalCustomerId) {
      newUserData["id"] = userData.externalCustomerId;
    }

    console.log(newUserData);

    setUserData(newUserData);

    const userEverLogged = userData?.userExtra.find(
      (prop) => prop.key === "MYVUSE_EVER_LOGGED"
    )?.value;
    const supportedServices = getSupportedServices();

    let nextStepUrl = "";

    if (
      Tenants.isGlobal() &&
      supportedServices.includes(servicesConstants.ONBOARDING_AV_YOTI) &&
      !userData?.isUserAgeVerified
    ) {
      nextStepUrl = routingConstants.HARD_AGE_VERIFICATION;
    } else if (userEverLogged === true || userEverLogged === "true") {
      const userEverLoggedTimestamp = userData?.userExtra.find(
        (prop) => prop.key === "MYVUSE_EVER_LOGGED"
      )?.createdAt;
      setUserEverLogged(userEverLoggedTimestamp);
      setDoneVault(true);
      setAcceptedTermsAndConditions(true);
      nextStepUrl = routingConstants.VAULT_RESTORE;
    } else {
      setUserEverLogged(null);
      setDoneVault(false);
      nextStepUrl = routingConstants.VAULT;
    }

    logAnalyticsEvent(
      AnalyticsEvents.LOG_IN_SUCCESS,
      {},
      {
        userID: newUserData.id,
      }
    );

    //reload tenant configuration
    dispatch(
      commonsActions.getCountryConfig(getTenantId(), (countryConfig) => {
        if (countryConfig?.data) {
          const currentLocale = getLocale();
          setAppConfig(countryConfig.data);
          setLocale(currentLocale);
        }
      })
    );

    //dispatch flag "myvuseConnected" (CA)  if we have to set to true
    let userMyvuseConnected = userData?.myvuseConnected;
    if (null != userMyvuseConnected && userMyvuseConnected === false) {
      dispatch(userActions.updateUserInfo());
    }

    //dispatch crm setting
    store.dispatch(
      userActions.setUserSettings(
        crmUserSettings.COOKIE_NOTICE,
        (store.getState().onboardingReducer.acceptedCookies === true).toString()
      )
    );

    //goto dashboard
    history.push({
      pathname: buildURI(nextStepUrl),
      state: {
        isAfterRegistration: false,
      },
    });
  };

  const getUserInfoError = () => {
    logAnalyticsEvent(AnalyticsEvents.LOG_IN_FAILURE);
  };

  return (
    <WebView
      handleBack={() => history.goBack()}
      url={url}
      id="webViewIframe"
      className="webViewIframe"
    />
  );
};
