import { AnalyticsEventModel } from "../_models/analyticsEvent.model";
import { debug } from "../_helpers/debug";
import { clearQueue } from "../_helpers/api/axiosQueue";

/* Initial state */
const APP_DATA_DEFAULT = {
  doneCountrySelection: false /* done country selection */,
  ageVerified: false /* done age verification */,
  doneOnboardingBenefits: false,
  doneVault: false,
  VPAttempts: 3,
  deviceUuid: null,
  vuseUuid: null,
  basket: [],
  errorLogs: [],
  userEverLogged: null,

  acceptedTermsAndConditions: null,
  loginData: null /* got login data */,
  registrationData: null /* got registration data */,
  doneNotificationPreferences: null /* navigated to notification prefs */,
  userData: null,
  showBatteryPercentageTip: true,
  showBatteryFullTip: true,
  showBatteryLifespanTip: null,
  lastBatteryForeacstCheck: null,
  analyticsUserProperties: {},

  firstPairing: null,
  applicationPrompt: false,
  applicationAdded: false,
  widgetAddReminder: null,
  widgetTutorial: false
};

export function initAppData() {
  debug("initAppData");
  let _appDataString = localStorage.getItem("appData");
  if (!_appDataString || _appDataString === "") {
    console.log("Init appData");
    localStorage.setItem("appData", JSON.stringify(APP_DATA_DEFAULT));
  } else {
    console.log("appData already present");
  }
}

export function softResetAppData() {
  debug("softResetAppData");
  AnalyticsEventModel.softResetAnalyticsUserProperties();

  const appData = {
    ...APP_DATA_DEFAULT,
    doneCountrySelection: true,
    ageVerified: true,
    analyticsUserProperties: getAnalyticsUserPropertiesFromLocalStorage(),
  };

  localStorage.setItem("appData", JSON.stringify(appData));

  clearQueue()
}

export function resetAppData() {
  debug("resetAppData");
  localStorage.setItem("appData", JSON.stringify(APP_DATA_DEFAULT));
}

export function getAgeVerified() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.ageVerified;
  } else {
    console.error("appData not initialized");
  }
}

export function setAgeVerified(ageVerified) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.ageVerified = ageVerified;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getDoneVault() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.doneVault;
  } else {
    console.error("appData not initialized");
  }
}

export function setDoneVault(doneVault) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.doneVault = doneVault;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getDoneCountrySelection() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.doneCountrySelection;
  } else {
    console.error("appData not initialized");
  }
}

export function setDoneCountrySelection(doneCountrySelection) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.doneCountrySelection = doneCountrySelection;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getDoneOnboardingBenefits() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.doneOnboardingBenefits;
  } else {
    console.error("appData not initialized");
  }
}

export function setDoneOnboardingBenefits(doneOnboardingBenefits) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.doneOnboardingBenefits = doneOnboardingBenefits;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getDoneNotificationPreferences() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.doneNotificationPreferences;
  } else {
    console.error("appData not initialized");
  }
}

export function setDoneNotificationPreferences(doneNotificationPreferences) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.doneNotificationPreferences = doneNotificationPreferences;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getAcceptedTermsAndConditions() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.acceptedTermsAndConditions;
  } else {
    console.error("appData not initialized");
  }
}

export function setAcceptedTermsAndConditions(acceptedTermsAndConditions) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.acceptedTermsAndConditions = acceptedTermsAndConditions;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getLoginData() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.loginData;
  } else {
    console.error("appData not initialized");
  }
}

export function setLoginData(loginData) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.loginData = loginData;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getRegistrationData() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.registrationData;
  } else {
    console.error("appData not initialized");
  }
}

export function setRegistrationData(registrationData) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.registrationData = registrationData;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getDoneDevicePairing() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.doneDevicePairing;
  } else {
    console.error("appData not initialized");
  }
}

export function setDoneDevicePairing(doneDevicePairing) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.doneDevicePairing = doneDevicePairing;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getDoneDeviceUnlock() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.doneDeviceUnlock;
  } else {
    console.error("appData not initialized");
  }
}

export function setDoneDeviceUnlock(doneDeviceUnlock) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.doneDeviceUnlock = doneDeviceUnlock;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getDoneSaveBattery() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.doneSaveBattery;
  } else {
    console.error("appData not initialized");
  }
}

export function setDoneSaveBattery(savedBattery) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.doneSaveBattery = savedBattery;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getAccessToken() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.accessToken;
  } else {
    console.error("appData not initialized");
  }
}

export function setAccessToken(accessToken) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.accessToken = accessToken;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getUserData() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.userData;
  } else {
    console.error("appData not initialized");
  }
}

export function getCustomerId() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.userData?.id;
  } else {
    console.error("appData not initialized");
  }
}

export function setUserData(userData) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);

    if (userData?.userId) {
      const { userId, ...othersData } = userData; 
      
      _appData.userData = {
        ..._appData.userData,
        id: userId,
        ...othersData
      };
    } else {
      _appData.userData = userData;
    }

    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function setOFPairing(paired) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.onboardingFeatures.pairing = paired;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOFPairing() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.onboardingFeatures.pairing;
  } else {
    console.error("appData not initialized");
  }
}

export function setOFPBattery(accepted) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.onboardingFeatures.battery = accepted;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOFBattery() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.onboardingFeatures.battery;
  } else {
    console.error("appData not initialized");
  }
}

export function setOFNotifications(choosen) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.onboardingFeatures.notifications = choosen;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOFNotifications() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.onboardingFeatures.notifications;
  } else {
    console.error("appData not initialized");
  }
}

export function getShowBatteryPercentageTip() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.showBatteryPercentageTip;
  } else {
    console.error("appData not initialized");
  }
}

export function setShowBatteryPercentageTip(showBatteryPercentageTip) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.showBatteryPercentageTip = showBatteryPercentageTip;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getShowLockAlert() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.showLockAlert;
  } else {
    console.error("appData not initialized");
  }
}

export function setShowLockAlert(showLockAlert) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.showLockAlert = showLockAlert;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getShowBatteryLifespanTip() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.showBatteryLifespanTip;
  } else {
    console.error("appData not initialized");
  }
}

export function setShowBatteryLifespanTip(showBatteryLifespanTip) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.showBatteryLifespanTip = showBatteryLifespanTip;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getShowBatteryFullTip() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.showBatteryFullTip;
  } else {
    console.error("appData not initialized");
  }
}

export function setShowBatteryFullTip(showBatteryFullTip) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.showBatteryFullTip = showBatteryFullTip;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getLastBatteryForeacstCheck() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.lastBatteryForeacstCheck;
  } else {
    console.error("appData not initialized");
  }
}

export function setLastBatteryForeacstCheck(lastBatteryForeacstCheck) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    _appData.lastBatteryForeacstCheck = lastBatteryForeacstCheck;
    localStorage.setItem("appData", JSON.stringify(_appData));
  } else {
    console.error("appData not initialized");
  }
}

export function setThings(things) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.userData = { ...appData.userData, things: things };
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getThings() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.userData?.things;
  } else {
    console.error("appData not initialized");
  }
}

export function setGroupId(group_id) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.userData = { ...appData.userData, group_id: group_id };
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function setLastOrder(last_order) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.userData = { ...appData.userData, last_order: last_order };
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getLastOrder() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.userData?.last_order;
  } else {
    console.error("appData not initialized");
  }
}

export function getDeviceUuid() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.deviceUuid;
  } else {
    console.error("appData not initialized");
  }
}

export function setDeviceUuid(uuid) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.deviceUuid = uuid;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function setVuseUuid(uuid) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.vuseUuid = uuid;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getVuseUuid() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.vuseUuid;
  } else {
    console.error("appData not initialized");
  }
}

export function getLastSync() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.lastSync;
  } else {
    console.error("appData not initialized");
  }
}

export function setLastSync(data) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.lastSync = data;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getLastConnection() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.lastConnection;
  } else {
    console.error("appData not initialized");
  }
}

export function setLastConnection(data) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.lastConnection = data;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getConnectionTimedOut() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.connectionTimedOut;
  } else {
    console.error("appData not initialized");
  }
}

export function getLastVapeMomentActivation() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.lastVapeMomentActivation;
  } else {
    console.error("appData not initialized");
  }
}

export function setLastVapeMomentActivation(data) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.lastVapeMomentActivation = data;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getSubscription() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData?.userData?.subscription;
  } else {
    console.error("appData not initialized");
  }
}

export function getBasket() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.basket;
  } else {
    console.error("appData not initialized");
  }
}

export function setBasket(basket) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.basket = basket;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getFeaturesSkipped() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.featuresSkip;
  } else {
    console.error("appData not initialized");
  }
}

export function setFeaturesSkipped(featuresSkip) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.featuresSkip = featuresSkip;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getTimerSettings() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.timerSettings;
  } else {
    console.error("appData not initialized");
  }
}

export function setTimerSettings(timerSettings) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.timerSettings = timerSettings;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getStoredPuffs() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.storedPuffs;
  } else {
    console.error("appData not initialized");
  }
}

export function setStoredPuffs(storedPuffs) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.storedPuffs = storedPuffs;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

/**** OPTINS ****/
export function setOptInWishlist(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.wishlist = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInWishlist() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.wishlist;
  } else {
    console.error("appData not initialized");
  }
}

export function setOptInFlavoursDiscovery(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.flavoursDiscovery = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInFlavoursDiscovery() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.flavoursDiscovery;
  } else {
    console.error("appData not initialized");
  }
}

export function setOptInFindMyVape(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.findMyVape = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInFindMyVape() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.findMyVape;
  } else {
    console.error("appData not initialized");
  }
}

export function setOptInVapeTimer(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.vapeTimer = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInVapeTimer() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.vapeTimer;
  } else {
    console.error("appData not initialized");
  }
}

export function setOptInStats(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.stats = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInStats() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.stats;
  } else {
    console.error("appData not initialized");
  }
}

export function setOptInPodInventory(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.podInventory = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInPodInventory() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.podInventory;
  } else {
    console.error("appData not initialized");
  }
}

export function setOptInConcierge(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.concierge = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInConcierge() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.concierge;
  } else {
    console.error("appData not initialized");
  }
}

export function setOptInSubscription(optedIn) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.optIns.subscription = optedIn;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getOptInSubscription() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.optIns.subscription;
  } else {
    console.error("appData not initialized");
  }
}

export function setLastAutomationsUpdate(lastUpdate) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.lastAutomationsUpdate = lastUpdate;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getLastAutomationsUpdate() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.lastAutomationsUpdate;
  } else {
    console.error("appData not initialized");
  }
}

export function getErrorLogs() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.errorLogs;
  } else {
    console.error("appData not initialized");
  }
}

export function setErrorLogs(errorLogs) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.errorLogs = errorLogs;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getVPAttempts() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.VPAttempts;
  } else {
    console.error("appData not initialized");
  }
}

export function setVPAttempts(VPAttempts) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.VPAttempts = VPAttempts;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function setUserEverLogged(timestamp) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.userEverLogged = timestamp;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getUserEverLogged() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData.userEverLogged;
  } else {
    console.error("appData not initialized");
  }
}

export function getAnalyticsUserPropertiesFromLocalStorage() {
  const appData = JSON.parse(localStorage.getItem("appData") || "{}");
  return appData.analyticsUserProperties || {};
}

export function setAnalyticsUserPropertiesInLocalStorage(userProperties) {
  const appData = JSON.parse(localStorage.getItem("appData") || "{}");
  appData.analyticsUserProperties = userProperties;
  localStorage.setItem("appData", JSON.stringify(appData));
}

export function getSalesforceId() {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString);
    return _appData.salesforceId;
  } else {
    console.error("appData not initialized");
  }
}

export function setSalesforceId(value) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData.salesforceId = value;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}

export function getRegistration() {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    return _appData.registration ? _appData.registration : {}
  } else {
    console.error("appData not initialized")
    return {}
  }
}

export function setRegistration(registration) {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    _appData.registration = registration
    localStorage.setItem("appData", JSON.stringify(_appData))
    window.dispatchEvent(new Event("storage"))
  } else {
    console.error("appData not initialized")
  }
}

export function getZip() {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    return _appData.zip
  } else {
    console.error("appData not initialized")
    return {}
  }
}

export function setZip(zip) {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    _appData.zip = zip
    localStorage.setItem("appData", JSON.stringify(_appData))
    window.dispatchEvent(new Event("storage"))
  } else {
    console.error("appData not initialized")
  }
}

export function getNotificationCheck() {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    return _appData.notificationCheck ? _appData.notificationCheck : {}
  } else {
    console.error("appData not initialized")
    return {}
  }
}

export function setNotificationCheck(notificationCheck) {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    _appData.notificationCheck = notificationCheck
    localStorage.setItem("appData", JSON.stringify(_appData))
    window.dispatchEvent(new Event("storage"))
  } else {
    console.error("appData not initialized")
  }
}

export function getRxDb15Migrated() {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    return _appData.rxDb15Migrated ? _appData.rxDb15Migrated : false
  } else {
    console.error("appData not initialized")
    return false
  }
}

export function setRxDb15Migrated(value) {
  let _appDataString = localStorage.getItem("appData")
  if (_appDataString) {
    let _appData = JSON.parse(_appDataString)
    _appData.rxDb15Migrated = value
    localStorage.setItem("appData", JSON.stringify(_appData))
    window.dispatchEvent(new Event("storage"))
  } else {
    console.error("appData not initialized")
  }
}

export function getStorageItem(itemName) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    return appData[itemName];
  } else {
    console.error("appData not initialized");
  }
}

export function setStorageItem(itemName, value) {
  let _appDataString = localStorage.getItem("appData");
  if (_appDataString) {
    const appData = JSON.parse(_appDataString);
    appData[itemName] = value;
    localStorage.setItem("appData", JSON.stringify(appData));
  } else {
    console.error("appData not initialized");
  }
}