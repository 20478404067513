import { ePod, ePod2, ePod3, SmartBox } from "@nodes-projects/bat-sdk-web";

const sdk = {
  epod: {
    connect: () => ePod.connect(),
  },
  ePod2: {
    connect: () => ePod2.connect(),
    setConnectionStateChanged: (callback) =>
      ePod2.setConnectionStateChanged(callback),
    setupConnectionConfig: (config) => ePod2.setupConnectionConfig(config),
    getDevice: () => ePod2.getDevice(),
    getDeviceInfo: () => ePod2.getDeviceInfo(),
    getDateTimeInfo: () => ePod2.getDateTimeInfo(),
    getLockInfo: () => ePod2.getLockInfo(),
    getBatteryInfo: () => ePod2.getBatteryInfo(),
    getLedInfo: () => ePod2.getLedInfo(),
    getFindVapeInfo: () => ePod2.getFindVapeInfo(),
    getCloudInfo: () => ePod2.getCloudInfo(),
    getHapticInfo: () => ePod2.getHapticInfo(),
    getPuffRecord: () => ePod2.getPuffRecord(),
    getErrorRecord: () => ePod2.getErrorRecord(),
    setDateTimeConfig: (unixTime) => ePod2.setDateTimeConfig(unixTime),
    setLockConfig: (value, withResponse = false) =>
      ePod2.setLockConfig(value, withResponse),
    setLedConfig: (value, withResponse = false) =>
      ePod2.setLedConfig(value, withResponse),
    setCloudConfig: (value, withResponse = false) =>
      ePod2.setCloudConfig(value, withResponse),
    setHapticInfo: (value, withResponse = false) =>
      ePod2.setHapticInfo(value, withResponse),
    getDeviceMSSPayload: () => ePod2.getDeviceMSSPayload(),
    setPayload: (value) => ePod2.setPayload(value),
    setPayloadVersion: (value) => ePod2.setPayloadVersion(value),
    setPayloadControl: (value) => ePod2.setPayloadControl(value),
    setPayloadChallenge: (value) => ePod2.setPayloadChallenge(value),
    requestPayload: (value) => ePod2.requestPayload(value),
    toggleFindMyVape: (value) => ePod2.toggleFindMyVape(value),
    setReset: () => ePod2.setReset(),
    subscribeToBatteryInfo: (listener) =>
      ePod2.subscribeToBatteryInfo(listener),
    subscribeToPayloadControl: (listener) =>
      ePod2.subscribeToPayloadControl(listener),
    subscribeToPayloadChallenge: (listener) =>
      ePod2.subscribeToPayloadChallenge(listener),
    subscribeToErrorRecord: (listener) =>
      ePod2.subscribeToErrorRecord(listener),
    subscribeToLogRecord: (listener) => ePod2.subscribeToLogRecord(listener),
    subscribeToPuffRecord: (listener) => ePod2.subscribeToPuffRecord(listener),
    subscribeToFindVapeInfo: (listener) =>
      ePod2.subscribeToFindVapeInfo(listener),
    subscribeToLockInfo: (listener) => ePod2.subscribeToLockInfo(listener),
    subscribeToPodConnectionStatus: (listener) => ePod2.subscribeToPodConnectionStatus(listener),
    isSmartPod: () => ePod2.isSmartPod(),
    isSupportedVRR: () => ePod2.isSupportedVRR(),
    setBatteryRRConfig: (value, withResponse = false) => ePod2.setBatteryRRConfig(value, withResponse),
    getBatteryRRInfo: () => ePod2.getBatteryRRInfo(),
    getPodConnectionStatus: () => ePod2.getPodConnectionStatus(),
    unsubscribeFromErrorRecord: () => ePod2.unsubscribeFromErrorRecord(),
    unsubscribeFromLogRecord: () => ePod2.unsubscribeFromLogRecord(),
    unsubscribeFromPayloadChallenge: () =>
      ePod2.unsubscribeFromPayloadChallenge(),
    unsubscribeFromPayloadControl: () => ePod2.unsubscribeFromPayloadControl(),
    unsubscribeFromPuffRecord: () => ePod2.unsubscribeFromPuffRecord(),
    unsubscribeFromFindVapeInfo: () => ePod2.unsubscribeFromFindVapeInfo(),
    unsubscribeFromBatteryInfo: () => ePod2.unsubscribeFromBatteryInfo(),
    unsubscribeFromLockInfo: () => ePod2.unsubscribeFromLockInfo(),
    unsubscribeFromPodConnectionStatus: () => ePod2.unsubscribeFromPodConnectionStatus(),
    ConnectionState: ePod2.ConnectionState,
    MSSProgressState: ePod2.MSSProgressState,

    setMarketSpecificSoftware: (value) =>
      ePod2.setMarketSpecificSoftware(value),
    setMSSProgressStateChanged: (listener) =>
      ePod2.setMSSProgressStateChanged(listener),
    setMSSPayloadUploadStateChanged: (listener) =>
      ePod2.setMSSPayloadUploadStateChanged(listener),
    getLoadedCharacteristics: () => ePod2.getLoadedCharacteristics(),
    getBleDevice: () => ePod2.getBleDevice(),
    isBSExisting: () => ePod2.isBSExisting(),
  },
  ePod3: {
    connect: () => ePod3.connect(),
    setConnectionStateChanged: (callback) =>
      ePod3.setConnectionStateChanged(callback),
    setupConnectionConfig: (config) => ePod3.setupConnectionConfig(config),
    getDevice: () => ePod3.getDevice(),
    getDeviceInfo: () => ePod3.getDeviceInfo(),
    getDateTimeInfo: () => ePod3.getDateTimeInfo(),
    getLockInfo: () => ePod3.getLockInfo(),
    getBatteryInfo: () => ePod3.getBatteryInfo(),
    getLedInfo: () => ePod3.getLedInfo(),
    getFindVapeInfo: () => ePod3.getFindVapeInfo(),
    getCloudInfo: () => ePod3.getCloudInfo(),
    getHapticInfo: () => ePod3.getHapticInfo(),
    getPuffRecord: () => ePod3.getPuffRecord(),
    getErrorRecord: () => ePod3.getErrorRecord(),
    setDateTimeConfig: (unixTime) => ePod3.setDateTimeConfig(unixTime),
    setLockConfig: (value, withResponse = false) =>
      ePod3.setLockConfig(value, withResponse),
    setLedConfig: (value, withResponse = false) =>
      ePod3.setLedConfig(value, withResponse),
    setCloudConfig: (value, withResponse = false) =>
      ePod3.setCloudConfig(value, withResponse),
    setHapticInfo: (value, withResponse = false) =>
      ePod3.setHapticInfo(value, withResponse),
    getDeviceMSSPayload: () => ePod3.getDeviceMSSPayload(),
    setPayload: (value) => ePod3.setPayload(value),
    setPayloadVersion: (value) => ePod3.setPayloadVersion(value),
    setPayloadControl: (value) => ePod3.setPayloadControl(value),
    setPayloadChallenge: (value) => ePod3.setPayloadChallenge(value),
    requestPayload: (value) => ePod3.requestPayload(value),
    toggleFindMyVape: (value) => ePod3.toggleFindMyVape(value),
    setReset: () => ePod3.setReset(),
    subscribeToBatteryInfo: (listener) =>
      ePod3.subscribeToBatteryInfo(listener),
    subscribeToPayloadControl: (listener) =>
      ePod3.subscribeToPayloadControl(listener),
    subscribeToPayloadChallenge: (listener) =>
      ePod3.subscribeToPayloadChallenge(listener),
    subscribeToErrorRecord: (listener) =>
      ePod3.subscribeToErrorRecord(listener),
    subscribeToLogRecord: (listener) => ePod3.subscribeToLogRecord(listener),
    subscribeToPuffRecord: (listener) => ePod3.subscribeToPuffRecord(listener),
    subscribeToFindVapeInfo: (listener) =>
      ePod3.subscribeToFindVapeInfo(listener),
    subscribeToLockInfo: (listener) => ePod3.subscribeToLockInfo(listener),
    subscribeToPodConnectionStatus: (listener) => ePod3.subscribeToPodConnectionStatus(listener),
    isSmartPod: () => ePod3.isSmartPod(),
    isSupportedVRR: () => ePod3.isSupportedVRR(),
    setBatteryRRConfig: (value, withResponse = false) => ePod3.setBatteryRRConfig(value, withResponse),
    getBatteryRRInfo: () => ePod3.getBatteryRRInfo(),
    getPodConnectionStatus: () => ePod3.getPodConnectionStatus(),
    unsubscribeFromErrorRecord: () => ePod3.unsubscribeFromErrorRecord(),
    unsubscribeFromLogRecord: () => ePod3.unsubscribeFromLogRecord(),
    unsubscribeFromPayloadChallenge: () =>
      ePod3.unsubscribeFromPayloadChallenge(),
    unsubscribeFromPayloadControl: () => ePod3.unsubscribeFromPayloadControl(),
    unsubscribeFromPuffRecord: () => ePod3.unsubscribeFromPuffRecord(),
    unsubscribeFromFindVapeInfo: () => ePod3.unsubscribeFromFindVapeInfo(),
    unsubscribeFromBatteryInfo: () => ePod3.unsubscribeFromBatteryInfo(),
    unsubscribeFromLockInfo: () => ePod3.unsubscribeFromLockInfo(),
    unsubscribeFromPodConnectionStatus: () => ePod3.unsubscribeFromPodConnectionStatus(),
    ConnectionState: ePod3.ConnectionState,
    MSSProgressState: ePod3.MSSProgressState,
    setMarketSpecificSoftware: (value) =>
      ePod3.setMarketSpecificSoftware(value),
    setMSSProgressStateChanged: (listener) =>
      ePod3.setMSSProgressStateChanged(listener),
    setMSSPayloadUploadStateChanged: (listener) =>
      ePod3.setMSSPayloadUploadStateChanged(listener),
    getLoadedCharacteristics: () => ePod3.getLoadedCharacteristics(),
    getBleDevice: () => ePod3.getBleDevice(),
    isBSExisting: () => ePod3.isBSExisting(),
  },
  SmartBox: {
    connect: () => SmartBox.connect(),
    setConnectionStateChanged: (callback) =>
      SmartBox.setConnectionStateChanged(callback),
    setupConnectionConfig: (config) => SmartBox.setupConnectionConfig(config),
    getDevice: () => SmartBox.getDevice(),
    getDeviceInfo: () => SmartBox.getDeviceInfo(),
    getDateTimeInfo: () => SmartBox.getDateTimeInfo(),
    getLockInfo: () => SmartBox.getLockInfo(),
    getBatteryInfo: () => SmartBox.getBatteryInfo(),
    getLedInfo: () => SmartBox.getLedInfo(),
    getFindVapeInfo: () => SmartBox.getFindVapeInfo(),
    getCloudInfo: () => SmartBox.getCloudInfo(),
    getHapticInfo: () => SmartBox.getHapticInfo(),
    getBuzzerInfo: () => SmartBox.getBuzzerInfo(),
    getPuffRecord: () => SmartBox.getPuffRecord(),
    getErrorRecord: () => SmartBox.getErrorRecord(),
    setDateTimeConfig: (unixTime) => SmartBox.setDateTimeConfig(unixTime),
    setLockConfig: (value, withResponse = false) =>
      SmartBox.setLockConfig(value, withResponse),
    setLedConfig: (value, withResponse = false) =>
      SmartBox.setLedConfig(value, withResponse),
    setCloudConfig: (value, withResponse = false) =>
      SmartBox.setCloudConfig(value, withResponse),
    setHapticInfo: (value, withResponse = false) =>
      SmartBox.setHapticInfo(value, withResponse),
    setBuzzerConfig: (value, withResponse = false) =>
      SmartBox.setBuzzerConfig(value, withResponse),
    getDeviceMSSPayload: () => SmartBox.getDeviceMSSPayload(),
    setPayload: (value) => SmartBox.setPayload(value),
    setPayloadVersion: (value) => SmartBox.setPayloadVersion(value),
    setPayloadControl: (value) => SmartBox.setPayloadControl(value),
    setPayloadChallenge: (value) => SmartBox.setPayloadChallenge(value),
    requestPayload: (value) => SmartBox.requestPayload(value),
    toggleFindMyVape: (value) => SmartBox.toggleFindMyVape(value),
    setReset: () => SmartBox.setReset(),
    subscribeToBatteryInfo: (listener) =>
      SmartBox.subscribeToBatteryInfo(listener),
    subscribeToPayloadControl: (listener) =>
      SmartBox.subscribeToPayloadControl(listener),
    subscribeToPayloadChallenge: (listener) =>
      SmartBox.subscribeToPayloadChallenge(listener),
    subscribeToErrorRecord: (listener) =>
      SmartBox.subscribeToErrorRecord(listener),
    subscribeToLogRecord: (listener) => SmartBox.subscribeToLogRecord(listener),
    subscribeToPuffRecord: (listener) => SmartBox.subscribeToPuffRecord(listener),
    subscribeToFindVapeInfo: (listener) =>
      SmartBox.subscribeToFindVapeInfo(listener),
    subscribeToLockInfo: (listener) => SmartBox.subscribeToLockInfo(listener),
    subscribeToPodConnectionStatus: (listener) => SmartBox.subscribeToPodConnectionStatus(listener),
    isSmartPod: () => SmartBox.isSmartPod(),
    isSupportedVRR: () => SmartBox.isSupportedVRR(),
    setBatteryRRConfig: (value, withResponse = false) => SmartBox.setBatteryRRConfig(value, withResponse),
    getBatteryRRInfo: () => SmartBox.getBatteryRRInfo(),
    getPodConnectionStatus: () => SmartBox.getPodConnectionStatus(),
    unsubscribeFromErrorRecord: () => SmartBox.unsubscribeFromErrorRecord(),
    unsubscribeFromLogRecord: () => SmartBox.unsubscribeFromLogRecord(),
    unsubscribeFromPayloadChallenge: () =>
      SmartBox.unsubscribeFromPayloadChallenge(),
    unsubscribeFromPayloadControl: () => SmartBox.unsubscribeFromPayloadControl(),
    unsubscribeFromPuffRecord: () => SmartBox.unsubscribeFromPuffRecord(),
    unsubscribeFromFindVapeInfo: () => SmartBox.unsubscribeFromFindVapeInfo(),
    unsubscribeFromBatteryInfo: () => SmartBox.unsubscribeFromBatteryInfo(),
    unsubscribeFromLockInfo: () => SmartBox.unsubscribeFromLockInfo(),
    unsubscribeFromPodConnectionStatus: () => SmartBox.unsubscribeFromPodConnectionStatus(),
    ConnectionState: SmartBox.ConnectionState,
    MSSProgressState: SmartBox.MSSProgressState,
    setMarketSpecificSoftware: (value) =>
      SmartBox.setMarketSpecificSoftware(value),
    setMSSProgressStateChanged: (listener) =>
      SmartBox.setMSSProgressStateChanged(listener),
    setMSSPayloadUploadStateChanged: (listener) =>
      SmartBox.setMSSPayloadUploadStateChanged(listener),
    getLoadedCharacteristics: () => SmartBox.getLoadedCharacteristics(),
    getBleDevice: () => SmartBox.getBleDevice(),

    getPowerSaveModeInfo: () => SmartBox.getPowerSaveModeInfo(),
    setPowerSaveModeConfig: (value) => SmartBox.setPowerSaveModeConfig(value),
    subscribeToPowerSaveMode: (listener) => SmartBox.subscribeToPowerSaveMode(listener),
    unsubscribeFromPowerSaveMode: () => SmartBox.unsubscribeFromPowerSaveMode(),
  },
};

export default sdk;
