import React, { useState, useRef, useEffect } from "react";
import sdk from "../../_vendors/nodes";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  setDeviceConnectionStateChanged,
  DEVICE_PROFILE,
  setDeviceMSSProgressStateChanged,
  dispatchSearchingErrorEvent,
  PAIRING_STEP_NAMES,
} from "../../_helpers/device";
import { debug } from "../../_helpers/debug";
import { Tenants } from "../../_helpers/tenants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { AnalyticsEvents, AnalyticsTargetEventsNames, AnalyticsScreenEventNames, routingConstants } from "../../_constants";
import { vuse } from "@nodes-projects/bat-sdk-web";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { deviceActions, getFirstPairing, getStorageItem, setFirstPairing, setStorageItem } from "../../_actions";
/**
 * Pairing flow fourth step
 * @param {*} props
 * @returns
 */
const DevicePairing = (props) => {
  const { dictionary, isVideoEnded = false, deviceProfile } = props;

  const [scan, setScan] = useState(false);
  const [nextStepEnabled, setNextStepEnabled] = useState(false);
  const [reconnect, setReconnect] = useState(false);
  const [subtitle, setSubtitle] = useState(dictionary.PAIRING_PRESS_BUTTON_FIVE_TIMES);

  const textTimer = useRef(null);

  const devices = useSelector((state) => state.deviceReducer.devices);
  const dispatch = useDispatch();

  useEffect(() => {
    async function myConnect() {
      if (scan) {
        const deviceInstance = vuse.setDevice(deviceProfile);

        deviceInstance.setMSSProgressStateChanged(function (connectionState) {
          return setDeviceMSSProgressStateChanged.apply(this, [
            connectionState,
            deviceInstance
          ]);
        }); // indicate progress of the whole MSS settinng up process

        deviceInstance.setConnectionStateChanged(function (connectionState) {
          return setDeviceConnectionStateChanged.apply(this, [
            connectionState,
            deviceInstance
          ]);
        });
        deviceInstance.setupConnectionConfig({
          reconnectEnabled: true, // auto-reconnect enabled
          connectionRetries: 3, // number of auto-reconnect attempts
          connectionRetryTimeout: 30 * 1000, // timeout each reconnect,
          scanTimeout: 35 * 1000, // Scan timeout
        });

        await deviceInstance
          .connect()
          .then(({ isConnected }) => {
            if (isConnected) {
              logAnalyticsEvent(AnalyticsEvents.DEVICE_PAIRING_SUCCESS);
            }
          })
          .catch((e) => {
            debug(`[Pairing] Connect error `, e);
            logAnalyticsEvent(AnalyticsEvents.DEVICE_PAIRING_FAILURE);
            dispatchSearchingErrorEvent();
          });
      }
    }

    myConnect();
  }, [scan]);

  useEffect(() => {
    if (props.isASAVCompleted) {
      setScan(true);
    }
  }, [props.isASAVCompleted]);

  /* The emitter is insisde setDeviceConnectionStateChanged and is triggered when the device is succesfully paired */
  useEffect(() => {
    /* Create a listener for pairing successfull */
    window.document.addEventListener("pairingSuccess", enableNextStep);
    window.document.addEventListener("pairingError", goToPairingFailurePage);
    window.document.addEventListener("disconnectEvent", setReconnectStatus);
    window.document.addEventListener(
      "searchingError",
      goToSearchingFailurePage
    );

    //check for bluetooth browser level permission
    if (BrowserHelper.isBLEBrowser()) {
      navigator.permissions.request({ name: "bluetooth", system: false })
    }

    /* cleanup the listener on unmount */
    return () => {
      window.document.removeEventListener(
        "searchingError",
        goToSearchingFailurePage
      );
      window.document.removeEventListener("pairingSuccess", enableNextStep);
      window.document.removeEventListener(
        "pairingError",
        goToPairingFailurePage
      );
      window.document.removeEventListener(
        "disconnectEvent",
        setReconnectStatus
      );
    };
  }, []);

  useEffect(() => {
    if (props.device && nextStepEnabled) {
      //set first device pairing time
      const pairingTime = new Date().getTime();

      const firstPairing = getStorageItem('firstPairing')
      if (!firstPairing) {
        setStorageItem('firstPairing', pairingTime)
      }

      goToNextStep(props.device);
    }
  }, [props.device, nextStepEnabled]);

  useEffect(() => {
    if (isVideoEnded){

      let textAlternator = true;
      let currentText;

      setSubtitle(dictionary.PAIRING_PAIRING_MODE);
      textTimer.current = setInterval(() => {

        textAlternator
          ? currentText = dictionary.PAIRING_PRESS_BUTTON_FIVE_TIMES
          : currentText = dictionary.PAIRING_PAIRING_MODE;
        setSubtitle(currentText);
        textAlternator = !textAlternator;

      }, 5000);

    }
    else{

      setSubtitle(dictionary.PAIRING_PRESS_BUTTON_FIVE_TIMES);
      clearInterval(textTimer.current);
    
    }
  }, [isVideoEnded]);

  /**
   * This set is needed because when "pairingSuccess" event is catched
   * updated device cannot be arrived to this component,
   * so we "enable" user to go to next step and when device
   * is updated user will be redirected to calculated next step
   */
  const enableNextStep = () => {
    setNextStepEnabled(true);
  };

  const setReconnectStatus = () => {
    setScan(false);
    setReconnect(true);
  };

  const goToNextStep = (device) => {
    // console.log("[PAIRING] goToNextStep device", device);
    setNextStepEnabled(false);

    if (typeof props.goToNamedStep === "function") {
      setTimeout(() => {
        //check on lock status
        // if (device?.lockInfo?.locked) {
        //     props.goToNamedStep(PAIRING_STEP_NAMES.UNLOCK);
        //     return;
        // }
        logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
          screen_name: AnalyticsScreenEventNames.TAKE_ME_TO_MYVUSE,
        });

        history.push(buildURI(routingConstants.DASHBOARD));
      }, 3000); //timeout for animation
    }
  };

  const goToSearchingFailurePage = () => {
    setScan(false);
    //Go to error page
    if (typeof props.goToNamedStep === "function") {
      props.goToNamedStep(PAIRING_STEP_NAMES.SEARCHING_ERROR);
    }
  };

  const goToPairingFailurePage = () => {
    setScan(false);
    //Go to error page
    if (typeof props.goToNamedStep === "function") {
      props.goToNamedStep(PAIRING_STEP_NAMES.PAIRING_ERROR);
    }
  };

  const onMyDeviceIsBlinkingButtonClick = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
    setScan(true); /*goToNextStep*/
  };

  // const onHelpLinkClick = () => {
  //     logAnalyticsEvent(AnalyticsEvents.NEED_SOME_HELP);
  //     alert("todo")
  // }

  return reconnect ? (
    <React.Fragment>
      <div className="pairing-title"></div>
      <PageSelectorCTA sticky={false} className="mt-auto">
        <div className="d-grid">
          <button
            className="btn btn-primary text-uppercase"
            onClick={() => setScan(true)}
          >
            {dictionary.PAIRING_BTN_CONNECT_0_ctaLabel}
          </button>
        </div>
      </PageSelectorCTA>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="pairing-title">
        <h1>{dictionary.PAIRING_PAIR_YOUR_DEVICE}</h1>
        <h2>{subtitle}</h2>
      </div>
      {isVideoEnded && (
        <PageSelectorCTA sticky={false} className="mt-auto">
          <p className="pair-device-info">{dictionary.PAIRING_KEEP_DEVICE_CLOSE}</p>
          <div className="d-grid">
            <button
              className="btn btn-primary text-uppercase"
              onClick={() => onMyDeviceIsBlinkingButtonClick()}
            >
              {dictionary.PAIRING_BTN_BLINKING_0_ctaLabel}
            </button>
          </div>
        </PageSelectorCTA>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  deviceProfile: state?.deviceReducer?.deviceProfile,
});

const connectedComponent = connect(mapStateToProps)(DevicePairing);
export { connectedComponent as DevicePairing };
