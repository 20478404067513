import { isEqual } from "lodash";
import React from "react";
import { connect } from "react-redux";
import ReactSlider from "react-slider";
import { deviceActions, userActions } from "../../_actions";
import { getDeviceSpec } from "../../_actions/appConfig.actions";
import { CustomSlidingPanel, Header, PageSelectorCTA } from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsFieldNames,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  MODE_BALANCED,
  MODE_BOLD,
  MODE_LOWPOWER,
  MODE_SMOOTH,
  PROFILE_EPOD2,
  PROFILE_SMARTBOX,
  PROFILE_WAWE2,
  aemPages,
  crmUserSettings,
  imagesConstants,
  routingConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import {
  logAnalyticsEvent,
  logAnalyticsEventForDevice,
} from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import {
  getDeviceThingFromSN,
  isBatterySaverSupported,
  isLowPowerModeEnabledBy,
  updateCloudConfig,
  updateDeviceInReducer,
  getSmartPodFlavourInfo,
  getDeviceInstanceFromSN,
  getDeviceName
} from "../../_helpers/device";
import { Utils } from "../../_helpers/graphic/utils";
import { history } from "../../_helpers/history";
import {
  buildURI,
  handleUIDeviceDisconnection,
} from "../../_helpers/navigation";
import { store } from "../../_store";
import { UltraPodWidget } from "../../_components/UltraPodWidget";
import cx from "classnames";
import HMAC_SHA256 from "crypto-js/hmac-sha256";

class CloudControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tempCurrentMode: null,
      tempPosition: null,
      currentMode: null, //default value
      currentWattage: 4.5,
      recommendedWattage: 0,
      currentHeatingPowerMode: false,
      deviceSpec: {},
      currentDevice: null,
      isBatterySaverSupported: false,
      batterySaverDisableAlertIsOpen: false,
      disableBatterySaver: false,
      isOpenTuning: false,
      flavour: null
    };

    this.goToTutorial = this._goToTutorial.bind(this);
    this.handleManualChangePower = this._handleManualChangePower.bind(this);
    this.changeMode = this._changeMode.bind(this);
    this.handleUpdateDeviceCloud = this._handleUpdateDeviceCloud.bind(this);
    this.goToDashboard = this._goToDashboard.bind(this);
    this.checkIsBatterySaverSupported =
      this.checkIsBatterySaverSupported.bind(this);
    this.handleAfterChange = this._handleAfterChange.bind(this);
    this.handleDisableBatterySaver = this._handleDisableBatterySaver.bind(this);
    this.handleCancelBatterySaver = this._handleCancelBatterySaver.bind(this);
    this.restorePreviousMode = this._restorePreviousMode.bind(this);
    this.handleChangeMode = this._handleChangeMode.bind(this);
    this.isWattageRecommended = this._isWattageRecommended.bind(this);
    this.setRecommendedPower = this._setRecommendedPower.bind(this);

    this.ultraPodWidgetRef = React.createRef();

    this.aem = new AEMHelper();
    this.dictionary = this.aem.getDictionary(aemPages.CLOUD_CONTROL, {
      CLOUD_CONTROL_HEADER: propertyHeadingDefaultEmpty,
      CLOUD_CONTROL_FLAV_EXP_MIN_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_SIZE_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_FLAV_EXP_MAX_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_FLAV_EXP_MID_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATT_USAGE_MIN_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_FLAV_EXP_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATT_USAGE_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_SIZE_MID_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATT_USAGE_MAX_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_SIZE_LP_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_LOW_POWER_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_SIZE_MAX_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATT_USAGE_MID_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_FLAV_EXP_LP_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_SIZE_MIN_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_MAX_LEVEL_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_MEDIOUM_LEVEL_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATT_USAGE_LP_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_MIN_LEVEL_LABEL: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATTERY_SAVER_HEADER: propertyHeadingDefaultEmpty,
      CLOUD_CONTROL_BATTERY_SAVER_ON_CONTENT: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATTERY_SAVER_ON_SUBCONTENT: propertyTextDefaultEmpty,
      CLOUD_CONTROL_BATTERY_SAVER_BTN_DISABLE: propertyCtaItemsDefaultEmpty,
      CLOUD_CONTROL_BATTERY_SAVER_BTN_CANCEL: propertyCtaItemsDefaultEmpty,

      CLOUD_CONTROL_POD_DETECTED: propertyTextDefaultEmpty,
      CLOUD_CONTROL_INTENSITY: propertyTextDefaultEmpty,
      CLOUD_CONTROL_FLAVOUR_AUTOTUNE: propertyTextDefaultEmptyParsed,
      CLOUD_CONTROL_FLAVOUR_DISABLED: propertyTextDefaultEmptyParsed,
      CLOUD_CONTROL_FLAVOUR_ENABLED: propertyTextDefaultEmptyParsed,
      CLOUD_CONTROL_BTN_FLAVOUR_ENABLE: propertyTextDefaultEmptyParsed,
      CLOUD_CONTROL_BTN_FLAVOUR_DISABLE: propertyTextDefaultEmptyParsed,
      CLOUD_CONTROL_ENABLED_AUTOMATICALLY: propertyTextDefaultEmptyParsed,
      CLOUD_CONTROL_TUTORIAL: propertyTextDefaultEmpty
    });
  }

  componentDidMount() {
    document.body.classList.add("cloud-control");

    const device =
      this.props.devices.find((device) => device.selected)
      ?? this.props.devices[0];
    handleUIDeviceDisconnection(device);

    this.checkIsBatterySaverSupported(device);

    const deviceProfile =
      device?.deviceType === PROFILE_EPOD2 ? PROFILE_WAWE2 : device?.deviceType;

    this.setState({ deviceSpec: getDeviceSpec(deviceProfile) }, () => {
      this.setState({
        currentMode: this.getModeFromWattage(device?.deviceType === PROFILE_SMARTBOX ? device?.cloudInfo?.actualPowerLevel : device?.cloudInfo?.powerLevel),
        currentWattage: ((device?.deviceType === PROFILE_SMARTBOX ? device?.cloudInfo?.actualPowerLevel : device?.cloudInfo?.powerLevel) ?? 10) / 10,
        currentHeatingPowerMode: device?.cloudInfo?.heatingPowerMode,
        recommendedWattage: device?.liquidInfo?.recommendedHeatingPower/10,
        currentDevice: device,
        flavour: getSmartPodFlavourInfo(device?.skuNumber)
      });
    });

    this.props.setUserSettings(
      crmUserSettings.CLOUD_CONTROL_PRESET_USED,
      "true"
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.devices !== this.props.devices) {
      // If device is no more present, redirect to homepage
      const device = this.props.devices.find((device) => device.selected);

      if (!isEqual(device, this.state.currentDevice)) {
        this.setState({
          ...this.state,
          currentMode: this.getModeFromWattage(device?.deviceType === PROFILE_SMARTBOX ? device?.cloudInfo?.actualPowerLevel : device?.cloudInfo?.powerLevel),
          currentWattage: ((device?.deviceType === PROFILE_SMARTBOX ? device?.cloudInfo?.actualPowerLevel : device?.cloudInfo?.powerLevel) ?? 10) / 10,
          currentHeatingPowerMode: device?.cloudInfo?.heatingPowerMode,
          recommendedWattage: device?.liquidInfo?.recommendedHeatingPower/10,
          currentDevice: device,
          flavour: getSmartPodFlavourInfo(device?.skuNumber)
        });
      }
 
      handleUIDeviceDisconnection(device);
    }

    const lowPowerValue = this.state.deviceSpec?.lowPower?.value / 10;

    if (
      prevState.currentWattage !== this.state.currentWattage &&
      this.state.currentDevice?.deviceType === PROFILE_SMARTBOX &&
      (
        prevState.currentWattage === lowPowerValue ||
        this.state.currentWattage === lowPowerValue
      )
    ) {

      let manualMode = false;
      if (this.state.currentWattage === lowPowerValue) manualMode = true;
      
      getDeviceInstanceFromSN(this.state.currentDevice?.serialNumber).then(deviceInstance => {
        deviceInstance.getPowerSaveModeInfo().then(powerSaveModeInfo => {
          deviceInstance.setPowerSaveModeConfig({...powerSaveModeInfo, manualMode});
        })
      });

    }
  }

  componentWillUnmount() {
    document.body.classList.remove("cloud-control");
  }

  getModeFromWattage(wattage) {
    const { deviceSpec } = this.state;

    //push in array all modes
    const modes = [...deviceSpec?.cloudSize, deviceSpec?.lowPower];
    const powerMode = modes.find((el) => el.value == wattage);
    return powerMode != null && powerMode !== undefined ? powerMode.type : null;
  }

  _handleManualChangePower(position) {
    console.log("[CLOUD CONTROL] manual position", position);

    if (position < 2.5) {
      position = 2.5;
    }

    this.setState({
      currentWattage: position,
      currentMode: this.getModeFromWattage(position * 10),
    });
  }

  _handleChangeMode(mode) {
    const { isBatterySaverSupported, currentDevice } = this.state;
    //logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {power_level: mode})

    switch(true){
      case(mode === 'BOLD'):
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {power_level: AnalyticsFieldNames.HIGH});
        break;
      case(mode === 'BALANCED'):
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {power_level: AnalyticsFieldNames.MEDIUM});
        break;
      case(mode === 'SMOOTH'):
        logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {power_level: AnalyticsFieldNames.LOW});
        break;
      default:
        break
    }

    if (
      isBatterySaverSupported &&
      currentDevice?.cloudInfo?.batterySavingEnabled === 1 &&
      currentDevice?.cloudInfo?.batterySavingOn === 1
    ) {
      this.setState({
        batterySaverDisableAlertIsOpen: true,
        tempCurrentMode: mode,
      });
    } else {
      this.changeMode(mode);
    }
  }

  _changeMode(mode) {
    const { deviceSpec } = this.state;

    //push in array all modes
    const modes = [...deviceSpec?.cloudSize, deviceSpec?.lowPower];
    //get the power object of requested mode
    let powerMode = modes.find((el) => el.type === mode);
    //get wattage from pawer value
    const power = (powerMode?.value ?? 10) / 10;
    //update state with new wattage
    this.setState(
      {
        currentWattage: power,
        currentMode: mode,
      },
      () => {
        //update device wattage
        this.handleUpdateDeviceCloud(power);
      }
    );

    console.log("[CLOUD CONTROL] button position", power);
  }

  _restorePreviousMode() {
    const { currentDevice } = this.state;

    const prevPowerLevel = currentDevice?.prevPowerLevel;
    const mode = this.getModeFromWattage(prevPowerLevel);

    console.debug("_restorePreviousMode", prevPowerLevel, mode);

    if (mode) {
      this.changeMode(mode);
    } else {
      this.handleUpdateDeviceCloud(prevPowerLevel / 10);
      this.handleManualChangePower(prevPowerLevel / 10);
    }
  }

  _handleUpdateDeviceCloud(power, isAutotuneAction = false) {
    const { devices, userPin } = this.props;

    const { currentMode, disableBatterySaver, currentHeatingPowerMode } = this.state;

    const deviceItem = devices.find((device) => device.selected);

    if (power < 2.5 && !isAutotuneAction) {
      power = 2.5;
    }

    updateCloudConfig(deviceItem, {
      ...(disableBatterySaver && { batterySavingEnabled: 0 }),
      powerLevel: power * 10,
      heatingPowerMode: currentHeatingPowerMode,
    }).then(() => {

      this.setState({
        batterySaverDisableAlertIsOpen: false,
        tempCurrentMode: null,
        tempPosition: null,
      });

      //Update BE
      const tenantUserId = Commons.generateTenantUserId(userPin);
      const deviceThing = getDeviceThingFromSN(deviceItem?.serialNumber);
      store.dispatch(
        deviceActions.setDeviceCloud(tenantUserId, deviceThing?.uuid, power)
      );
    });
  }

  _goToTutorial() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TUTORIAL});
    history.push(buildURI(routingConstants.CLOUD_CONTROL_TUTORIAL), {type: 'slide'});
  }

  _goToDashboard() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE});
    history.push(buildURI(routingConstants.DASHBOARD));
  }

  checkIsBatterySaverSupported(device) {
    isBatterySaverSupported(device)
      .then((isSupported) => {
        console.debug(
          "[CloudControl] checkIsBatterySaverSupported",
          isSupported
        );
        this.setState({
          ...this.state,
          isBatterySaverSupported: isSupported,
        });
      })
      .catch((e) => {
        console.debug("[CloudControl] checkIsBatterySaverSupported", e);
      });
  }

  _handleAfterChange(position) {
    const { isBatterySaverSupported, currentDevice } = this.state;
    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {power_level: position + 'W'})

    if (
      isBatterySaverSupported &&
      currentDevice?.cloudInfo?.batterySavingEnabled === 1 &&
      currentDevice?.cloudInfo?.batterySavingOn === 1
    ) {
      this.setState({
        batterySaverDisableAlertIsOpen: true,
        tempPosition: position,
      });
    } else {
      this.handleUpdateDeviceCloud(position);
    }
  }

  _handleDisableBatterySaver() {
    this.setState(
      {
        disableBatterySaver: true,
      },
      () => {
        if (this.state.tempCurrentMode) {
          this.changeMode(this.state.tempCurrentMode);
        } else if (this.state.tempPosition) {
          this.handleUpdateDeviceCloud(this.state.tempPosition);
        } else {
          this.restorePreviousMode();
        }
      }
    );
  }

  _handleCancelBatterySaver() {
    const { deviceSpec } = this.state;
    const lowPowerValue = parseInt(deviceSpec?.lowPower?.value, 10) ?? 25;

    this.setState({
      batterySaverDisableAlertIsOpen: false,
      tempCurrentMode: null,
      tempPosition: null,
      currentMode: MODE_LOWPOWER,
      currentWattage: lowPowerValue / 10,
    });
  }

  _isWattageRecommended(){
    return this.state.currentWattage == this.state.recommendedWattage
  }

  openAutotuneDrawer = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ULTRA_SETTINGS});
    this.setState(
      {isOpenTuning: true},
      () => { logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.ULTRA_SETTINGS}); }
    );
  }

  closeAutotuneDrawer = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CANCEL});
    this.setState({isOpenTuning: false});
  }

  switchPowerMode = () => {
    this.setState({
      isOpenTuning: false,
      currentHeatingPowerMode: !this.state.currentHeatingPowerMode
    }, () => {
      if (this.state.currentHeatingPowerMode) {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DISABLE})
      } else {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.ENABLE})
      }

      const data = {
        "serialNumber": HMAC_SHA256(
          this.state.currentDevice?.serialNumber,
          store.getState().onboardingReducer.userPin
        ).toString(),
        "optin": !this.state.currentHeatingPowerMode,
        "timestamp": (new Date()).toISOString()
      }

      store.dispatch(userActions.setUserSettings(
        crmUserSettings.OPT_IN_AUTOTUNE,
        JSON.stringify(data)
      ))

      this.handleUpdateDeviceCloud(0, true);
    });
  }

  _setRecommendedPower = () => {
    this.setState({
      currentWattage: this.state.recommendedWattage,
      currentMode: this.getModeFromWattage(this.state.recommendedWattage * 10),
    }, () => {
      this.handleUpdateDeviceCloud(this.state.recommendedWattage);
    });
  }

  render() {
    const {
      currentWattage,
      currentMode,
      deviceSpec,
      currentDevice,
      batterySaverDisableAlertIsOpen,
      ultraPodWidgetRef,
      isOpenTuning,
      flavour
    } = this.state;

    const currentDisplayedWattage = `${Number(currentWattage).toFixed(1)}w`;
    const isLowPowerModeActive = isLowPowerModeEnabledBy(
      currentDevice,
      deviceSpec
    );
    const cloudIntensityGradientHeigth = (currentWattage - 2) * 10 * 7.25 + 90;
    const batteryIcon = Utils.getBatteryIconFromPercentage(
      currentDevice?.batteryInfo,
      (currentDevice?.deviceType === PROFILE_SMARTBOX ? currentDevice?.cloudInfo?.actualPowerLevel : currentDevice?.cloudInfo?.powerLevel) ?? 10,
      isLowPowerModeActive
    );

    return (
      <React.Fragment>
        <div className="page w-100">
          <Header
            leftButton={{
              className: "play-tutorial-high-header cloud-control-tutorial",
              icon:
                <>
                  <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />
                  <span>{this.dictionary.CLOUD_CONTROL_TUTORIAL}</span>
                </>,
              onClick: this.goToTutorial,
            }}
            rightButton={{
              icon: <img src={imagesConstants.CLOUD_CONTROL_CLOSE} />,
              onClick: this.goToDashboard,
            }}
            high={true}
          >
            {this.dictionary.CLOUD_CONTROL_HEADER}
          </Header>
          <div className={cx('cloud-control-wrapper', {'to-bottom': currentDevice?.deviceType === PROFILE_SMARTBOX})}>
            <div>
              <h1 className="cloud-control-watt">
                <span>{currentDisplayedWattage}</span>
              </h1>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 pe-4">
                  <button
                    className="cloud-control-cta cloud-control-cta-bold"
                    onClick={() => this.handleChangeMode(MODE_BOLD)}
                  >
                    <div className="circles">
                      <img src={
                        currentMode === MODE_BOLD
                          ? imagesConstants.CLOUD_CONTROL_HIGH_ACTIVE
                          : imagesConstants.CLOUD_CONTROL_HIGH_DEFAULT
                        }/>
                    </div>
                    {this.dictionary.CLOUD_CONTROL_MAX_LEVEL_LABEL}
                  </button>
                  <button
                    className="cloud-control-cta cloud-control-cta-balanced"
                    onClick={() => this.handleChangeMode(MODE_BALANCED)}
                  >
                    <div className="circles">
                      <img src={
                        currentMode === MODE_BALANCED
                          ? imagesConstants.CLOUD_CONTROL_MEDIUM_ACTIVE
                          : imagesConstants.CLOUD_CONTROL_MEDIUM_DEFAULT
                        }/>
                    </div>
                    {this.dictionary.CLOUD_CONTROL_MEDIOUM_LEVEL_LABEL}
                  </button>
                  <button
                    className="cloud-control-cta cloud-control-cta-smooth"
                    onClick={() => this.handleChangeMode(MODE_SMOOTH)}
                  >
                    <div className="circles">
                      <img src={
                        currentMode === MODE_SMOOTH
                          ? imagesConstants.CLOUD_CONTROL_LOW_ACTIVE
                          : imagesConstants.CLOUD_CONTROL_LOW_DEFAULT
                        }/>
                    </div>
                    {this.dictionary.CLOUD_CONTROL_MIN_LEVEL_LABEL}
                  </button>
                </div>
                {deviceSpec && currentWattage && (
                  <ReactSlider
                    className="cloud-control-vertical-slider d-flex justify-content-center flex-shrink-1"
                    min={deviceSpec.min / 10}
                    max={deviceSpec.max / 10}
                    step={0.1}
                    markClassName="example-mark"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    orientation="vertical"
                    value={currentWattage}
                    invert
                    renderThumb={(props, state) => {
                      return (
                        <div
                          {...props}
                          className={`${props.className} ${
                            state.valueNow !== currentWattage ? "d-none" : ""
                          }`}
                        >
                          {state.valueNow}
                        </div>
                      );
                    }}
                    onAfterChange={(position) =>
                      this.handleAfterChange(position)
                    }
                    onChange={(position) =>
                      this.handleManualChangePower(position)
                    }
                    onBeforeChange={(position) =>
                      console.log("position", position)
                    }
                  />
                )}
              </div>
              {currentDevice?.isSmartPod && flavour &&
              <UltraPodWidget
                flavour={flavour}
                ref={ultraPodWidgetRef}
                device={currentDevice}
                absolute={false}
                updateHeight={() => null}
                connectionStatusLabel={this.dictionary.CLOUD_CONTROL_POD_DETECTED}
                intensityLabel={this.dictionary.CLOUD_CONTROL_INTENSITY}
                autoTune={true}
                addClass="p-0 mt-5"
                onAutoTuneClick={this.openAutotuneDrawer}
                isWattageRecommended = {this.isWattageRecommended()}
                setRecommendedPower = {this.setRecommendedPower}
              />
              }
            </div>

            <div>
              <div className="cloud-control-settings">
                <div className="cloud-control-settings-value d-flex">
                  {this.dictionary.CLOUD_CONTROL_SIZE_LABEL}
                  <span className="ms-auto">
                    {
                      this.dictionary[
                        Utils.getLabelsFromCloudControlWattage(
                          currentWattage,
                          currentDevice
                        ).labelcs
                      ]
                    }
                  </span>
                </div>
                <div className="cloud-control-settings-value d-flex">
                  {this.dictionary.CLOUD_CONTROL_FLAV_EXP_LABEL}
                  <span className="ms-auto">
                    {
                      this.dictionary[
                        Utils.getLabelsFromCloudControlWattage(
                          currentWattage,
                          currentDevice
                        ).labelfe
                      ]
                    }
                  </span>
                </div>
                <div className="cloud-control-settings-value d-flex">
                  {this.dictionary.CLOUD_CONTROL_BATT_USAGE_LABEL}
                  <span className="ms-auto">
                    {
                      this.dictionary[
                        Utils.getLabelsFromCloudControlWattage(
                          currentWattage,
                          currentDevice
                        ).labelbu
                      ]
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`cloud-control-intensity ${
              isLowPowerModeActive ? "low" : ""
            }`}
            key={isLowPowerModeActive}
            style={{ 
              height: `${cloudIntensityGradientHeigth}px`, 
              background: (
                (this.isWattageRecommended() && currentDevice?.isSmartPod && flavour)
                ? flavour?.color
                : undefined
              )
            }}
          ></div>
          <div className="page-linear-gradient-bg-top-left"></div>
        </div>
        <CustomSlidingPanel
          from="bottom"
          isOpen={batterySaverDisableAlertIsOpen}
          overlayClassName="sliding-common-wrapper priority"
          className="onboarding-panel mid-height-panel"
          title={this.dictionary.CLOUD_CONTROL_BATTERY_SAVER_HEADER}
          backdropClose={false}
        >
          <div className="process-data-container px-0 pb-0">
            <div className="process-data-title">
              <div>
                {Utils.stringReplacePlaceholders(
                  this.dictionary.CLOUD_CONTROL_BATTERY_SAVER_ON_CONTENT,
                  [`<strong>${getDeviceName(currentDevice)}</strong>`],
                  true
                )}
              </div>
              <br />
              <div>
                {this.dictionary.CLOUD_CONTROL_BATTERY_SAVER_ON_SUBCONTENT}
              </div>
            </div>
            <div className="process-data-image">
              <span className="bat-icon-battery-in-charge" />
            </div>
            <div className="process-data-cta with-bg d-grid gap-2">
              <button
                className="btn btn-primary text-uppercase"
                onClick={this.handleDisableBatterySaver}
              >
                {
                  this.dictionary
                    .CLOUD_CONTROL_BATTERY_SAVER_BTN_DISABLE_0_ctaLabel
                }
              </button>
              <button
                className="btn btn btn-outline-secondary text-uppercase"
                onClick={this.handleCancelBatterySaver}
              >
                {
                  this.dictionary
                    .CLOUD_CONTROL_BATTERY_SAVER_BTN_CANCEL_0_ctaLabel
                }
              </button>
            </div>
          </div>
        </CustomSlidingPanel>

        <CustomSlidingPanel
          from="bottom"
          isOpen={isOpenTuning}
          title={this.dictionary.CLOUD_CONTROL_FLAVOUR_AUTOTUNE}
          overlayClassName="sliding-common-wrapper homepage-priority"
          className="onboarding-panel mid-height-panel notification-check"
          backdropClose={false}
        >
          <div className="process-data-container">
            <div className="process-data-title">
              <div className="mb-3">
                {this.state.currentHeatingPowerMode
                ? this.dictionary.CLOUD_CONTROL_FLAVOUR_DISABLED
                : this.dictionary.CLOUD_CONTROL_FLAVOUR_ENABLED}
              </div>
              <div>
                {this.dictionary.CLOUD_CONTROL_ENABLED_AUTOMATICALLY}
              </div>
            </div>
            <img className="autotune-icon" src={imagesConstants.CLOUD_CONTROL_AUTOTUNE} />
          </div>
          <PageSelectorCTA sticky={true}>
            <div className="d-grid gap-2">
              <button
                className="btn btn-primary text-uppercase"
                onClick={this.switchPowerMode}
              >
                {this.state.currentHeatingPowerMode
                ? this.dictionary.CLOUD_CONTROL_BTN_FLAVOUR_ENABLE
                : this.dictionary.CLOUD_CONTROL_BTN_FLAVOUR_DISABLE}
              </button>
              
              <button
                className="btn text-uppercase btn-outline-secondary"
                onClick={this.closeAutotuneDrawer}
              >
                {this.dictionary.CLOUD_CONTROL_BATTERY_SAVER_BTN_CANCEL_0_ctaLabel}
              </button>
            </div>
          </PageSelectorCTA>
        </CustomSlidingPanel>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    deviceReducer: { devices },
    onboardingReducer: { userPin },
  } = state;

  return {
    devices,
    userPin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserSettings: (settingName, value, successCb, failureCb) => {
      dispatch(
        userActions.setUserSettings(settingName, value, successCb, failureCb)
      );
    },
  };
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CloudControl);
export { connectedComponent as CloudControl };
