import React, { createContext } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Badge from '@mui/material/Badge';
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";
import isNil from 'lodash/isNil';
import { PodTracker, PodTrackerOptin, PodTrackerOptinInstructions, Shop, VuseWorld } from '..';
import { 
    servicesConstants, 
    aemPages, 
    CUSTOMER, 
    tipsEvents, 
    flagStatus, 
    thingVuseProperties, 
    crmUserSettings,
    webviewUrl,
    AnalyticsEvents,
    AnalyticsTargetEventsNames,
    dashboardTabsConstants,
    AnalyticsScreenEventNames,
    imagesConstants,
    routingConstants,
} from '../../_constants';
import { NavBar, Header } from '../../_components';
import { getSupportedServices, getUrl, getNavbar, isSupportedService } from '../../_actions/appConfig.actions';
import { Notifications } from '../../_helpers/notifications';
import { Tenants } from '../../_helpers/tenants';
import { Commons } from '../../_helpers/commons';
import { 
    AEMHelper, 
    propertyImageSrcDefaultNull, 
    propertyTextDefaultEmpty 
} from '../../_helpers/aem/aemhelper';
import { 
    commonsActions, 
    getVuseUuid, 
    userActions, 
    shopActions, 
    getAccessToken, 
    setDoneNotificationPreferences, 
    getUserData
} from '../../_actions';
import { NotificationCenter } from '../NotificationCenter/NotificationCenter';
import { WebviewModal } from '../../_components/WebviewModal/WebviewModal';
import { UserHelper } from '../../_helpers/user/user.helper';
import { ThingsHelper } from '../../_helpers/things';
import { debug } from '../../_helpers/debug';
import { store } from '../../_store';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { NotificationPermissionHandler } from '../../_components/NotificationPermissionPanels/NotificationPermissionHandler';
import { NotificationCheckPanel } from '../../_components/NotificationPermissionPanels/NotificationCheckPanel';
import BrowserHelper from '../../_helpers/browser/browser.helper';
import { PermissionsHelper } from '../../_helpers/permissions/permissions.helper';
import { convertObjectToQueryString, getTruncateAppVersion } from '../../_helpers/utils';
import { WebView, MoreMenu } from '../../_components';
import { ManageAccount } from "../../Views/ManageAccount";
import { HomeScreen } from '../Dashes/HomeScreen';
import { getElementHeight } from '../../_helpers/dom';
import HealthWarning from '../../_components/HealthWarning';
import cx from "classnames";
import { CheckoutHelper } from '../../_helpers/checkout';
import { shopServices } from '../../_services';
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';

export const DashboardContext = createContext(null);

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.aem = new AEMHelper();
        this.dictionary = {
            ...this.aem.getDictionary(aemPages.HOMEPAGE, {
                HEALTH_WARNING: propertyImageSrcDefaultNull,
                HOME_LABEL_UPDATES_AVAILABLE: propertyTextDefaultEmpty,
                HOME_MENU_SHOP: propertyTextDefaultEmpty,
                HOME_MENU_VUSE_WORLD: propertyTextDefaultEmpty,
                HOME_MENU_DEVICE: propertyTextDefaultEmpty,
            }),
            ...this.aem.getDictionary(aemPages.POD_TRACKER_OPTIN, {
                PD_OPTIN_TUTORIAL: propertyTextDefaultEmpty
            })
        };

        this.headerRef = React.createRef();
        this.navbarRef = React.createRef();

        this.state = {
            tabsActions: [
                { label: this.dictionary.HOME_MENU_DEVICE, key: 0, iconClassName: "bat-icon-vuse-brand", disabled: false, badgeNum: 0, service: servicesConstants.HOME_DEVICE },
                { label: this.dictionary.HOME_MENU_SHOP, key: 1, iconClassName: "bat-icon-cart", disabled: false, badgeNum: 0, service: servicesConstants.HOME_SHOP },
                { label: this.dictionary.HOME_MENU_VUSE_WORLD, key: 2, iconClassName: "bat-icon-vuse-logo", disabled: false, badgeNum: 0, service: servicesConstants.HOME_VUSE_WORLD }
            ],
            activeTab: dashboardTabsConstants.DEVICE,
            activeSection: 0, //active subtab in shop tab
            activeCategory: 0, //active category in shop/buynow tab
            currentDevice: null,
            webviewEl: {},
            isOpenWebviewModal: false,
            needToAskUserForNotificationPermission: false,
            preferences: [],
            navbar: [],
            showCheckPermissionPanel: false,
            oneTimeToken: null,
        }

        this.handleNavigationTabs = this._handleNavigationTabs.bind(this);
        this.goToBuyNow = this._goToBuyNow.bind(this);
        this.goToVuseWorld = this._goToVuseWorld.bind(this);
        this.goToSubscriptions = this._goToSubscriptions.bind(this);
        this.goToReorder = this._goToReorder.bind(this);
        this.goToDevice = this._goToDevice.bind(this);
        this.goToAccount = this._goToAccount.bind(this);
        this.openWebview = this._openWebview.bind(this);
        this.toggleUpdatesPanel = this._toggleUpdatesPanel.bind(this);
        this.openShop = this._openShop.bind(this);

        window.document.addEventListener('logAnalytics', this.logAnalytics);
    }


    componentDidMount() {
        document.body.classList.add("dashboard");
        document.body.style.overflow = '';

        const {
            userPin,
            getCatalog,
            getThings,
            catalog,
            hasUserAlreadyReplyForNotificationPermission,
            setUserSettings,
            history,
            getUserInfo
        } = this.props;

        const tenantUserId = Commons.generateTenantUserId(userPin);

        //load updated customer thing
        getThings(tenantUserId, getVuseUuid(), CUSTOMER, (response) => {
            const things = response?.result?.code === 0
                ? response?.data[0]?.properties
                : [];

            debug("[getThings] ", things.reduce((acc, thing) => acc + " - " + JSON.stringify(thing), ""));

            this.setState({
                // handle only the push notification things
                preferences: things.filter(ThingsHelper.isAPushNotificationThing)
            }, () => {
                debug(
                    "ThingsHelper.areAcceptedAnyPushNotificationThings(things) ", ThingsHelper.areAcceptedAnyPushNotificationThings(things),
                    " - UserHelper.isUserLoggedIn() ", UserHelper.isUserLoggedIn(),
                    " - hasUserAlreadyReplyForNotificationPermission ", hasUserAlreadyReplyForNotificationPermission
                )
                if (
                    ThingsHelper.areAcceptedAnyPushNotificationThings(things) &&
                    UserHelper.isUserLoggedIn() &&
                    !hasUserAlreadyReplyForNotificationPermission
                ) {
                    this.askUserTheNotificationPermission();
                } else {
                    if (BrowserHelper.isBLEBrowser()) {
                        setTimeout(() => {
                            navigator.permissions.query({name: 'notifications', system: true})
                            .then(response => {
                                debug(`INFO: [Nuviu] checkPushNotificationPermissions ${JSON.stringify(response)}`);
                                if (PermissionsHelper.hasPromptStatus(response.state)) {
                                    BrowserHelper.enablePWANotificationPermission();
                                } 
                                return response
                            })
                            .catch(err => {
                                debug(`ERROR: [Nuviu] checkPWANotificationPermissionStatus ${JSON.stringify(err)}`);
                                return Promise.reject(err);
                            })
                        }, 1000);
                    }
                }
            })

            this.setState({ showCheckPermissionPanel: true });
        });

        setUserSettings(crmUserSettings.APP_VERSION, getTruncateAppVersion());

        /* START navigation props handling */
        if (window.location.hash) {
            this.setState({ activeTab: window.location.hash })
        } else if (history?.location?.state?.activeTab) {
            window.history.replaceState(null, null, history.location.state.activeTab)
            this.setState({ activeTab: history.location.state.activeTab })
        } else {
            window.history.replaceState(null, null, this.state.activeTab)
        }
        
        Notifications.handleLocalNotifications();
        Notifications.handleRemoteNotifications();

        /* END navigation props handling */

        //load catalog if expired
        // if (!Tenants.isCanadaDark()) {
        //     if (catalog === null || catalog === undefined || catalog.length <= 0) {
        //         getCatalog(tenantUserId, getVuseUuid());
        //     }
        // }

        window.document.addEventListener(tipsEvents.GO_TO_FLAVOUR_FOR_YOU, this.goToReorder);
        window.document.addEventListener(tipsEvents.GO_TO_FAVOURITES, this.goToReorder);
        window.document.addEventListener(tipsEvents.GO_TO_BUY_NOW, this.goToBuyNow);
        window.document.addEventListener(tipsEvents.GO_TO_VUSE_WORLD, this.goToVuseWorld);
        window.document.addEventListener(tipsEvents.GO_TO_SUBSCRIPTION, this.goToSubscriptions);
        window.document.addEventListener(tipsEvents.GO_TO_MY_ACCOUNT, this.goToAccount);
        window.document.addEventListener(tipsEvents.GO_TO_HOME, this.goToDevice);
        window.document.addEventListener(tipsEvents.GO_TO_WEBVIEW, this.openWebview);
        window.document.addEventListener('checkTipsQueue', this.handleNotifications);

        Notifications.handleRemoteNotificationActionFromPushNotification();

        this.setState({navbar: getNavbar()})

        if (isSupportedService(servicesConstants.SHOP_OTP)) {
            shopServices.getOneTimeToken().then((tokenResponse)=> {
                this.setState({
                    oneTimeToken: tokenResponse?.data?.oneTimeAuthToken || null
                });
            });
        }

        //we need to do this to trigger the logout if the user is deleted
        const userData = getUserData()
        const userId = userData?.id
        getUserInfo(userId)
    }

    componentWillUnmount() {
        // document.body.classList.remove("dashboard", "warning-active");
        window.document.removeEventListener(tipsEvents.GO_TO_FLAVOUR_FOR_YOU, this.goToReorder);
        window.document.removeEventListener(tipsEvents.GO_TO_FAVOURITES, this.goToReorder);
        window.document.removeEventListener(tipsEvents.GO_TO_BUY_NOW, this.goToBuyNow);
        window.document.removeEventListener(tipsEvents.GO_TO_VUSE_WORLD, this.goToVuseWorld);
        window.document.removeEventListener(tipsEvents.GO_TO_SUBSCRIPTION, this.goToSubscriptions);
        window.document.removeEventListener(tipsEvents.GO_TO_MY_ACCOUNT, this.goToAccount);
        window.document.removeEventListener(tipsEvents.GO_TO_HOME, this.goToDevice);
        window.document.removeEventListener(tipsEvents.GO_TO_WEBVIEW, this.openWebview);
        window.document.removeEventListener('checkTipsQueue', this.handleNotifications);
        window.document.removeEventListener('logAnalytics', this.logAnalytics);
    }

    componentDidUpdate(prevProps, prevState) {

        //checking on changed devices permits to redirect to one specific device slide in device tab
        //actually used for notifications action purpose
        // if (!_.isEqual(prevProps.devices, this.props.devices)) {

        //     const currentSelectedDevice = this.props.devices.find(device => device.selected);
        //     if (currentSelectedDevice !== null && currentSelectedDevice !== undefined) {
        //         console.log("[CURRENT DEVICE] [DASHBOARD]", currentSelectedDevice);
        //         this.setState({
        //             currentDevice: currentSelectedDevice,
        //             //activeTab: 0
        //         });
        //     }
        // }

        if (prevState.activeTab !== this.state.activeTab) {
            console.log("activeTab", this.state.activeTab);
        }
    }

    logAnalytics(event) {
        if (!Notifications.isHandlingAPushNotification()) {
            logAnalyticsEvent(...event.detail);
        }
    }

    askUserTheNotificationPermission() {
        debug("[DASHBOARD] askUserTheNotificationPermission")
        this.props.setIsUpdatingThingsToIoTPlatform(true);

        this.setState({
            needToAskUserForNotificationPermission: true
        })
    }

    afterAskingTheUserForNotificationPermissionCallback() {
        debug("[DASHBOARD] afterAskingTheUserForNotificationPermissionCallback")
        this.props.setIsUpdatingThingsToIoTPlatform(false);

        this.setState({
            needToAskUserForNotificationPermission: false
        })
    }

    updateAndSavePreferences() {
        // do nothing, in positive case there is no need to do anything
        return new Promise(resolve => {
            this.afterSavePreferences();
            resolve();
        });
    }

    resetAndSavePreferences() {
        const {
            preferences
        } = this.state;

        const resetPreferences = preferences.map(thing => {
            if (ThingsHelper.isAPushNotificationThing(thing)) {
                return {
                    ...thing,
                    data: flagStatus.REFUSED
                };
            } else {
                return thing;
            }
        })

        this.props.setUserPreferences({
            userOptedInForPushNotifications: false
        });

        return this.savePreferences(resetPreferences);
    }

    savePreferences(preferences){
        const {
            userPin,
            setThingsAsync
        } = this.props;

        const tenantUserId = Commons.generateTenantUserId(userPin);

        return setThingsAsync(
            preferences,
            tenantUserId,
            getVuseUuid(),
            CUSTOMER
        ).then((response) => {
            if (response === true) {
                this.setState({
                    preferences
                }, () => {
                    this.afterSavePreferences();
                })
            } else {
                store.dispatch(commonsActions.showErrorAlert(true));
            }
        })
    }

    afterSavePreferences() {
        //set flag for navigation purpose
        setDoneNotificationPreferences(true);

        //hanlde SUGGESTION notifications
        const suggestionsThing = this.state.preferences.find(thing => thing.type === thingVuseProperties.SUGGESTIONS);
        if (suggestionsThing?.data === flagStatus.ACCEPTED) {
            Notifications.handleSuggestionNotifications();
        }
    }


    /**
     * 
     */
    handleNotifications() {
        setTimeout(() => {    
            //deliver all notifications in queue
            const tipsQueue = store.getState().dashboardReducer.tips;
            if(!isNil(tipsQueue) && Commons.isValidArray(tipsQueue)){
                debug("[QUEUE] handleNotifications ", JSON.stringify(tipsQueue));
                tipsQueue.forEach(tip => {
                    Notifications.dispatchNotification(tip, false);
                });
            }
        }, 2000)
    }

    _handleNavigationTabs(tab, activeSection, activeCategory, callback) {

        console.log('[DASHBOARD] activeTab', tab);
        console.log('[DASHBOARD] activeSection', activeSection);
        console.log('[DASHBOARD] activeCategory', activeCategory);

        window.history.replaceState(null, null, tab)

        this.setState({
            activeTab: tab,
            ...(!isNil(activeSection) && { activeSection: activeSection }),
            ...(!isNil(activeCategory) && { activeCategory: activeCategory }),
            isOpenUpdatesPanel: false //if navigation request comes from updates, updates panel is needed to be closed
        }, () => {
            // console.log('[DASHBOARD] next activeTab', this.state.activeTab);
            // console.log('[DASHBOARD] next activeSection', this.state.activeSection);
            // console.log('[DASHBOARD] next activeCategory', this.state.activeCategory);
            if (typeof callback === "function") {
                callback();
            }

            if(tab === dashboardTabsConstants.ACCOUNT){
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.MANAGE_ACCOUNT });
            } else if(tab === dashboardTabsConstants.STORE_LOCATOR){
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.STORE_LOCATOR });
            } else if(tab === dashboardTabsConstants.VUSE_WORLD){
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.VUSE_WORLD });
            } else if(tab === dashboardTabsConstants.DEVICE){
                logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.HOME });
            } else if(tab === dashboardTabsConstants.POD_TRACKER){
                //logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.HOME });
            }

        });
    }

    _goToBuyNow(e) {
        this.handleNavigationTabs(dashboardTabsConstants.STORE_LOCATOR, 0);
    }

    _goToVuseWorld(e) {
        this.handleNavigationTabs(dashboardTabsConstants.VUSE_WORLD);
    }

    _goToDevice() {
        this.handleNavigationTabs(dashboardTabsConstants.DEVICE);
    }

    _goToReorder(e) {
        this.handleNavigationTabs(dashboardTabsConstants.STORE_LOCATOR, 1, null, () => {
            setTimeout(() => {
                if (e?.type === tipsEvents.GO_TO_FLAVOUR_FOR_YOU) {
                    window.document.dispatchEvent(new CustomEvent('openFlavoursForYou'));
                } else if (e?.type === tipsEvents.GO_TO_FAVOURITES) {
                    window.document.dispatchEvent(new CustomEvent('openFavourites'));
                }
            }, 0)
        });
    }

    _goToAccount() {
        this.handleNavigationTabs(dashboardTabsConstants.ACCOUNT);
    }

    _openWebview(event) {
    //     console.log("event", event);
    //     console.log("event?.detail?.webview_title", event?.detail?.webview_title);
    //     console.log("event.detail.webview_url", event.detail.webview_url);
    //     console.log("event.detail.webview_restricted", event.detail.webview_restricted);
        if (
            event?.detail?.webview_url !== null &&
            event?.detail?.webview_url !== undefined &&
            event?.detail?.webview_url !== ""
        ) {
            this.setState({
                webviewEl: {
                    title: event.detail.webview_title,
                    url: `${event.detail.webview_url}${event.detail.webview_restricted ? `?token=${getAccessToken()}` : ''}`
                },
                isOpenWebviewModal: true
            }, () => {
                this.setState({ isOpenUpdatesPanel: false });
            });
        }
    }

    _openShop() {
        const analyticsToken = CheckoutHelper.getANewAnalyticsToken();
        const shopUrl = getUrl(webviewUrl.WEBVIEW_SHOP);

        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SHOP});

        if (isSupportedService(servicesConstants.SHOP_OTP)) {
            console.log("[oneTimeToken] oneTimeToken: ", this.state.oneTimeToken);
            window.open(shopUrl + convertObjectToQueryString({
                "utm_medium": "myvuseapp",
                ...(this.state.oneTimeToken && {
                    token: this.state.oneTimeToken
                }),
                reqOrigin: "myvuse",
                analyticsToken: `myvuse_${analyticsToken}`
            }), "_blank");

        } else {
            window.open(shopUrl + convertObjectToQueryString({
                "utm_medium": "myvuseapp",
                reqOrigin: "myvuse",
                analyticsToken: `myvuse_${analyticsToken}`
            }), "_blank");
        }
    }

    _goToSubscriptions() {
        this.handleNavigationTabs(dashboardTabsConstants.STORE_LOCATOR, 2);
    }

    _toggleUpdatesPanel() {
        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NOTIFICATION_CENTRE})
        if(this.state.activeTab === 0){
            
        }
        this.setState({ isOpenUpdatesPanel: !this.state.isOpenUpdatesPanel }, () => {
            /*
            if (this.state.isOpenUpdatesPanel) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
            */
        });
    }

    isPodTracker() {
        const { activeTab } = this.state;

        return activeTab === dashboardTabsConstants.POD_TRACKER ||
            activeTab === dashboardTabsConstants.POD_TRACKER_OPTIN ||
            activeTab === dashboardTabsConstants.POD_TRACKER_OPTIN_INSTRUCTIONS
    }

    renderLeftButton() {
        const isDarkMarket = Tenants.isCanadaDark();

        if (this.isPodTracker()) {
            const goToTutorial = () => {
                logAnalyticsEvent(
                    AnalyticsEvents.CLICK,
                    {target: AnalyticsTargetEventsNames.TUTORIAL}
                );
                history.push(buildURI(routingConstants.POD_TRACKER_TUTORIAL), {type: 'slide'});
            };

            return {
                className: "play-tutorial-high-header",
                icon: <>
                    <img src={imagesConstants.CLOUD_CONTROL_PLAY_TUTORIAL} />
                    <span>{this.dictionary.PD_OPTIN_TUTORIAL}</span>
                </>,
                onClick: goToTutorial,
            }
        } else if (!isDarkMarket) {
            return {
                icon: (
                <span className="notification-icon">
                    <Badge
                    color="secondary"
                    variant="dot"
                    invisible={!Notifications.areNotificationsUnread()}
                    >
                    <img src={imagesConstants.HOMESCREEN_NOTIFICATIONS} />
                    </Badge>
                </span>
                ),
                onClick: this.toggleUpdatesPanel
            }
        } else {
            return null
        }
    }

    renderRightButton() {
        const isDarkMarket = Tenants.isCanadaDark();
        const isSupportedShopTab = getSupportedServices().includes(servicesConstants.HOME_SHOP);

        if (!isDarkMarket && isSupportedShopTab && !this.isPodTracker()) {
            return {
                icon: (
                    <span className='bat-icon-cart' />
                ),
                onClick: this.openShop
            }
        } else {
            return null
        }
    }

    render() {

        const {
            notifications,
            nextPage,
            loadingNotifications
        } = this.props;

        const {
            tabsActions,
            activeTab,
            isOpenUpdatesPanel,
            currentDevice,
            activeSection,
            activeCategory,
            webviewEl,
            isOpenWebviewModal,
            showCheckPermissionPanel
        } = this.state;


        let filteredTabs = tabsActions.filter((tab) => getSupportedServices().includes(tab.service));
        filteredTabs = _.compact(filteredTabs);

        let isSupportedDeviceTab = getSupportedServices().includes(servicesConstants.HOME_DEVICE);
        let isSupportedShopTab = getSupportedServices().includes(servicesConstants.HOME_SHOP);
        let isSupportedWWorldTab = getSupportedServices().includes(servicesConstants.HOME_VUSE_WORLD);

        // console.log("filteredTabs", filteredTabs);
        // console.log("isSupportedDeviceTab", isSupportedDeviceTab);
        // console.log("isSupportedShopTab", isSupportedShopTab);
        // console.log("isSupportedWWorldTab", isSupportedWWorldTab);

        
        //let areShownTabs = filteredTabs.length > 1;

        // const isHealthWarning = this.dictionary.HEALTH_WARNING !== null;
        // if(isHealthWarning){
        //     document.body.classList.add("warning-active");
        // }

        return (
            <DashboardContext.Provider value={{
                headerHeight: getElementHeight(this.headerRef.current),
                navbarHeight: getElementHeight(this.navbarRef.current),
            }}>

                <div className={cx("w-100 h-100", {'homescreen': activeTab === dashboardTabsConstants.DEVICE})} style={{minHeight: "100%"}}>

                    {(activeTab === dashboardTabsConstants.STORE_LOCATOR || activeTab === dashboardTabsConstants.VUSE_WORLD) && <HealthWarning />}
                
                    <Header
                        ref={this.headerRef}
                        leftButton={this.renderLeftButton()}
                        rightButton={this.renderRightButton()}
                        transparent={true}
                        isAbsolute={activeTab === dashboardTabsConstants.DEVICE}
                    />

                    <NavBar 
                        ref={this.navbarRef} 
                        isMoreMenuOpen={this.state.activeTab === dashboardTabsConstants.MENU} 
                        onNavigate={(tab) => this.handleNavigationTabs(tab)} 
                        selected={this.props?.location?.state?.selected}
                    />

                    {
                        activeTab === dashboardTabsConstants.DEVICE && isSupportedDeviceTab &&
                        <HomeScreen headerRef={this.headerRef} navbarRef={this.navbarRef} />
                        // <Device device={currentDevice} areShownTabs={areShownTabs}
                        //     navigateToDevices={() => this.handleNavigationTabs(1, 0, 1)} />
                    }

                    {
                        activeTab === dashboardTabsConstants.STORE_LOCATOR && isSupportedShopTab && (this.state.navbar.includes('STORE_LOCATOR_AND_SHOP') || this.state.navbar.includes('ONLY_STORE')) &&
                        <Shop activeSection={activeSection}
                            activeCategory={activeCategory}
                            navigateToVuseWorld={this.goToVuseWorld}
                            showLocator={this.state.navbar.includes('STORE_LOCATOR_AND_SHOP')}
                        />
                    }

                    {
                        activeTab === dashboardTabsConstants.STORE_LOCATOR && this.state.navbar.includes('STORE_LOCATOR') &&
                        <div className='iframe-only-shop'>
                            <WebView
                                url={`${getUrl(webviewUrl.WEBVIEW_STORE_LOCATOR)}`}
                                id="extPageIframe"
                                className="extPageIframe"
                                showHeader={false}
                            />
                        </div>
                    }

                    {
                        activeTab === dashboardTabsConstants.VUSE_WORLD && isSupportedWWorldTab && this.state.navbar.includes('VUSE_WORLD') &&
                        <VuseWorld />
                    }

                    {
                        activeTab === dashboardTabsConstants.ACCOUNT && this.state.navbar.includes('ACCOUNT') &&
                        <ManageAccount header={false} />
                    }

                    {
                        activeTab === dashboardTabsConstants.MENU && 
                        <MoreMenu />
                    }

                    {
                        activeTab === dashboardTabsConstants.POD_TRACKER && 
                        <PodTracker header={false} />
                    }
                    {
                        activeTab === dashboardTabsConstants.POD_TRACKER_OPTIN && 
                        <PodTrackerOptin header={false} />
                    }
                    {
                        activeTab === dashboardTabsConstants.POD_TRACKER_OPTIN_INSTRUCTIONS && 
                        <PodTrackerOptinInstructions header={false} />
                    }

                    {
                        <NotificationCenter isShown={isOpenUpdatesPanel}
                            dictionary={this.dictionary}
                            nextPage={nextPage}
                            fetchNotifications={() => Notifications.handleNotificationCenterNotifications()}
                            notifications={notifications}
                            loading={loadingNotifications}
                            onClose={this.toggleUpdatesPanel}
                        />
                    }
                    {
                        <WebviewModal
                            webviewId="homepageIframe"
                            webviewClassName="homepageIframe"
                            webviewTitle={webviewEl?.title}
                            webviewuUrl={webviewEl?.url}
                            handleBack={() => {
                                this.setState({ 
                                    isOpenWebviewModal: false,
                                    webviewEl: {} 
                                });
                            }}
                            isModalOpen={isOpenWebviewModal}
                            modalClassName='webview-modal-priority'
                        />
                    }

                    <NotificationPermissionHandler
                        needToAskUserForNotificationPermission={this.state.needToAskUserForNotificationPermission}
                        afterAskingTheUserForNotificationPermissionCallback={this.afterAskingTheUserForNotificationPermissionCallback.bind(this)}
                        askAfterUserInteraction={false}
                        updateAndSavePreferences={this.updateAndSavePreferences.bind(this)}
                        resetAndSavePreferences={this.resetAndSavePreferences.bind(this)}
                    />

                    {showCheckPermissionPanel &&
                    <NotificationCheckPanel />
                    }
                </div>
            </DashboardContext.Provider>

        );

    }
}

function mapStateToProps(state) {

    const {
        dashboardReducer: {
            loadingNotifications,
            notifications,
            nextPage
        },
        permissionsReducer: {
            hasUserAlreadyReplyForNotificationPermission
        }
    } = state;

    return {
        userPin: state.onboardingReducer.userPin,
        catalog: state.shopReducer.catalog,
        devices: state.deviceReducer.devices,
        loadingNotifications,
        notifications,
        nextPage,
        hasUserAlreadyReplyForNotificationPermission
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCatalog: (tenantUserId, uuid, successCb, failureCb) => {
            dispatch(shopActions.getCatalog(tenantUserId, uuid, successCb, failureCb));
        },
        getUserInfo: (userId, successCb, failureCb) => {
            dispatch(userActions.getUserInfo(userId, successCb, failureCb));
        },
        getThings: (tenantUserId, uuid, vendor, successCb, failureCb) => {
            dispatch(commonsActions.getThings(tenantUserId, uuid, vendor, successCb, failureCb));
        },
        setThingsAsync: (things, tenantUserId, uuid, vendor) => {
            return new Promise((resolve, reject) => {
                dispatch(commonsActions.setThings(things, tenantUserId, uuid, vendor, resolve, reject));
            })
        },
        setUserPreferences: (data, successCb, failureCb) => {
            dispatch(userActions.updateUserInfo(data, successCb, failureCb))
        },
        setIsUpdatingThingsToIoTPlatform: (toggle) => {
            dispatch(commonsActions.setIsUpdatingThingsToIoTPlatform(toggle))
        },
        setUserSettings: (settingName, value, successCb, failureCb) => {
            dispatch(userActions.setUserSettings(settingName, value, successCb, failureCb))
        }
    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export { connectedComponent as Dashboard };