import React, { useEffect, useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import { ThemeProvider, createTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getCountry, getLocale, isLightMarket } from '../../_actions/appConfig.actions';
import { isEmpty, isNil } from 'lodash';
import parse from "html-react-parser"
import '../../_assets/faq_json/faq-page.css';
import { Header } from '../../_components';
import { history } from '../../_helpers/history';
import { Commons } from '../../_helpers/commons';
import { AEMHelper, propertyTextDefaultEmptyParsed, propertyCtaItemsDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { AnalyticsEvents, aemPages } from '../../_constants';
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

SwiperCore.use([Navigation, Pagination]);

const defaultTheme = createTheme()

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    marginBottom: 28,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
    padding: 0
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'block'
  },
}))(MuiAccordionDetails);



export const FAQ = () => {
  let {lang, market, os} = useParams();
  const [previewSize, setPreviewSize] = useState({});
  const [dictionary, setDictionary] = useState({});
  const [numVisibleItems, setNumVisibleItems] = useState({});
  let jsonDataFaq = null

  if(lang) {
    jsonDataFaq = market
      ? require(`../../_assets/FAQ/${lang}/${os}/${market}/faq.json`)
      : require(`../../_assets/FAQ/${lang}/${os}/faq.json`);   
      
  }else {
    const locale = getLocale();
    const isLight = isLightMarket() ? "light" : "dark";
    const country = getCountry();
    if(isNil(os)){
      os = "ios"
    }

    jsonDataFaq = country === 'CA' 
      ? require(`../../_assets/FAQ/${locale}/${os}/${isLight}/faq.json`)
      : require(`../../_assets/FAQ/${locale}/${os}/faq.json`);

  }

  useEffect(() => {
    const aem = new AEMHelper();
    setDictionary({
      ...aem.getDictionary(aemPages.BURGER_MENU, {
        MENU_DEVICE_APP_FAQ: propertyTextDefaultEmptyParsed,
      }),
      ...aem.getDictionary(aemPages.DEVICE_FAQ, {
        FAQ_SHOW_MORE: propertyCtaItemsDefaultEmptyParsed,
        FAQ_SHOW_LESS: propertyCtaItemsDefaultEmptyParsed
      })
    });
      const initialPreviewSize = {};
      jsonDataFaq.groups.forEach((group, groupIndex) => {
        initialPreviewSize[groupIndex] = group.preview_size;
        setNumVisibleItems((prevNumVisibleItems) => ({
          ...prevNumVisibleItems,
          [groupIndex]: group.preview_size,
        }));
      });
      setPreviewSize(initialPreviewSize);
  }, []);

  const handleShowMore = (groupIndex) => {
    setNumVisibleItems((prevNumVisibleItems) => ({
      ...prevNumVisibleItems,
      [groupIndex]: jsonDataFaq.groups[groupIndex].items.length,
    }));
  };

  const handleShowLess = (groupIndex) => {
    setNumVisibleItems((prevNumVisibleItems) => ({
      ...prevNumVisibleItems,
      [groupIndex]: previewSize[groupIndex],
    }));
  };

  const handleBack = () => {
    history.goBack()
  }

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  const Content = ({ contentData, groupIndex, groupTitle, groupDefinition }) => {
    const swiperRefs = contentData.map(() => React.createRef());

    return (
      <div>
        {contentData.map((faq, contentIndex) => {
          const content = jsonDataFaq.content.find((item) => item.id === faq.content_id);
          
          if (!content) {
            console.warn(`Content not found for content_id: ${faq.content_id}`);
            return null;
          }
          const isSlides = content.type === 'slides';
          const title = groupTitle[contentIndex] || content.title
          const header = groupDefinition[contentIndex]?.header || content.definition.header
          const footer = groupDefinition[contentIndex]?.footer || content.definition.footer

          return (
            <ThemeProvider theme={defaultTheme}>
              <Accordion key={contentIndex} elevation={0} onChange={(e,t) => {
              if (t){
                logAnalyticsEvent(
                  AnalyticsEvents.FAQ_VIEW,
                  {
                    group: groupIndex,
                    item: contentIndex
                  }
                );
              }
            }}>
                <AccordionSummary expandIcon={<div className="dropdown-arrow up" />}>
                  {parse(title)}
                </AccordionSummary>
                {isSlides ? (
                  <div>
                    <AccordionDetails>
                      <div>{parse(header)}</div>
                      <div className="container-slides">
                        <div onClick={() => swiperRefs[contentIndex].current.swiper.slidePrev()} style={{width:'40%', height: 'auto'}}></div>
                        <Swiper pagination={pagination} ref={swiperRefs[contentIndex]}>
                          {content.definition.slides.map((slide, slideIndex) => (
                            <SwiperSlide key={slideIndex} className="d-flex flex-column">
                              {slide.title && <div>{parse(slide.title)}</div>}
                              {slide.body && <div>{parse(slide.body)}</div>}
                              <div className="container-slide-image">
                              {
                                (() => {
                                  try {
                                    return <img src={require('../../_assets/img/faq' + slide.image)} alt="" width="64px" height="auto"  style={{objectFit: "scale-down"}}/>;
                                  } catch (error) {
                                    console.warn("IMAGE", error);
                                    return null;
                                  }
                                })()
                              }
                                {slide.pointer && (
                                  <div
                                    style={{ top: `${slide.pointer.posY}%`, left: `${slide.pointer.posX}%` }}
                                    className="pointer-css"
                                    onClick={() => swiperRefs[contentIndex].current.swiper.slideNext()}
                                  ></div>
                                )}
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                        <div onClick={() => swiperRefs[contentIndex].current.swiper.slideNext()} style={{width:'40%', height: 'auto'}}></div>
                      </div>
                    </AccordionDetails>
                    <h3>{parse(footer)}</h3>
                  </div>
                ) : (
                  <AccordionDetails>{parse(content.definition)}</AccordionDetails>
                )}
              </Accordion>
            </ThemeProvider>
          );
        })}
      </div>
    )
  };

  const Group = ({ groupData, groupIndex }) => {
    const numItems = groupData.items.length;
    const groupName = groupData.items.map((content) => content.title)
    const groupDefinition = groupData.items.map((content) => content.definition)

    return (
      <div>
        <div className='header-container'>
          <h1 className='header-title'>{groupData.title}</h1>
          {
            (() => {
              try {
                if (isEmpty(groupData.icon))
                  return null;
                return <img src={require('../../_assets/img/faq/icons/' + groupData.icon)} alt="" />
              } catch (error) {
                console.warn("ICON", error);
                return null;
              }
            })()
          }
        </div>
        <Content contentData={groupData.items.slice(0, numVisibleItems[groupIndex])} groupIndex={groupIndex} groupTitle={groupName} groupDefinition={groupDefinition}/>
        {numItems > previewSize[groupIndex] && (
          <div>
            {numVisibleItems[groupIndex] === previewSize[groupIndex] ? (
              <div
                className='btn-show'
                onClick={() => handleShowMore(groupIndex)}
              >
                SHOW MORE
              </div>
            ) : (
              <div
                className='btn-show'
                onClick={() => handleShowLess(groupIndex)}
              >
                SHOW LESS
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {os === "ios" && <Header rightButton={{
          icon: <span className="bat-icon-close" />,
          onClick: handleBack
      }}>
        {dictionary.MENU_DEVICE_APP_FAQ}
      </Header>}
      <div className='faq-page'>
        {jsonDataFaq.groups.map((group, groupIndex) => (
          <Group key={groupIndex} groupData={group} groupIndex={groupIndex} />
        ))}
      </div>
    </div>
   
  );
};
