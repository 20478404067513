import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getUserData, setDoneNotificationPreferences, userActions } from "../../_actions";
import { isSupportedService } from "../../_actions/appConfig.actions";
import {
  ContactPanel,
  CustomSlidingPanel,
  Header,
  PageSelectorCTA,
  SpinnerModal,
  UserAvatar,
} from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsTargetEventsNames,
  AnalyticsFieldNames,
  aemPages,
  crmUserSettings,
  flagStatus,
  routingConstants,
  servicesConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed
} from "../../_helpers/aem/aemhelper";
import { getAnalyticStatusBy, logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { buildURI } from "../../_helpers/navigation";
import { Tenants } from "../../_helpers/tenants";
import { UserHelper } from "../../_helpers/user/user.helper";
import { Webview } from "../../_helpers/webview";
import { shopServices } from "../../_services";
import { history } from '../../_helpers/history';
import { Notifications } from "../../_helpers/notifications";
import { NotificationPermissionHandler } from "../../_components/NotificationPermissionPanels/NotificationPermissionHandler";
import { store } from "../../_store";
import cx from "classnames";
import { convertObjectToQueryString } from "../../_helpers/utils";
import { CustomLoadingButton } from '../../_components/CustomLoadingButton';
import { AxiosCancelTokensHolder } from "../../_helpers/api/axiosCancelTokensHolder";

const ManageAccount = ({ getUserInfo, header = true, setUserPreferencesAsync }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dictionary, setDictionary] = useState({});
  const [user, setUser] = useState({});
  const [oneTimeToken, setOneTimeToken] = useState(null);
  const [isLogoutPanelOpen, setIsLogoutPanelOpen] = useState(false);
  const [isOpenContactPanel, setIsOpenContactPanel] = useState(false);
  const [crmPreferences, setCrmPreferences] = useState({});
  const [toggleUpdated, setToggleUpdated] = useState(null);
  const [things, setThings] = useState([])
  const [needToAskUserForNotificationPermission, setNeedToAskUserForNotificationPermission] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [spinnerError, setSpinnerError] = useState(false)
  const [isSupportedSms, setIsSupportedSms] = useState(isSupportedService(servicesConstants.MARKETING_OPTIN_SMS));

  const handleClose = () => {
    Commons.goToDashboard({isMoreMenuOpen: true})
  };

  const handleEditDetails = () => {
    // logAnalyticsEvent(AnalyticsEvents.EDIT_DETAILS); --> old
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.EDIT});

    if (Tenants.isCanadaDark()) {
      history.push({
        pathname: buildURI(routingConstants.EDIT_ACCOUNT)
      });
    } else {
      window.open(Webview.getMyAccountUrl(oneTimeToken), "_blank");
    }
  };

  const handleDownloadData = () => {
    // logAnalyticsEvent(AnalyticsEvents.DOWNLOAD_DATA_REQ); --> old
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DOWNLOAD});
    window.open(Webview.getDownloadDataUrl(), "_blank");
  };

  const handleDeleteAccount = () => {
    //logAnalyticsEvent(AnalyticsEvents.DELETE_ACCOUNT_REQ); --> old
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DELETE});

    window.open(Webview.getDeleteAccountUrl(oneTimeToken), "_blank");
    if (isSupportedService(servicesConstants.SHOP_OTP)) {
      getOneTimeToken();
    }
  };

  const handleMailSupport = () => {
    // logAnalyticsEvent(AnalyticsEvents.DELETE_ACCOUNT); --> old
    window.location.href = `mailto:${dictionary.MY_ACCOUNT_CLOSE_EMAIL}`; //?subject=Feedback&body=Message
  }

  const getOneTimeToken = useCallback(async () => {
    try {
      const res = await shopServices.getOneTimeToken();
      const token = res?.data?.oneTimeAuthToken;

      if (token) {
        setOneTimeToken(token);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleLogout = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.LOGOUT});
    setButtonLoading(true);
    UserHelper.logout();
  };

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary({
      ...aem.getDictionary(aemPages.MANAGE_ACCOUNT, {
        MY_ACCOUNT_HEADER: propertyHeadingDefaultEmpty,
        MY_ACCOUNT_BTN_EDIT_DETAILS: propertyCtaItemsDefaultEmpty,

        MY_ACCOUNT_DOWNLOAD_DATA_TITLE: propertyTextDefaultEmpty,
        MY_ACCOUNT_DOWNLOAD_DATA_CONTENT: propertyTextDefaultEmpty,
        MY_ACCOUNT_BTN_DOWNLOAD_DATA: propertyCtaItemsDefaultEmpty,
        MY_ACCOUNT_DELETE_ACCOUNT_TITLE: propertyTextDefaultEmpty,
        MY_ACCOUNT_DELETE_ACCOUNT_CONTENT: propertyTextDefaultEmpty,
        MY_ACCOUNT_BTN_DELETE_ACCOUNT: propertyCtaItemsDefaultEmpty,

        MY_ACCOUNT_BTN_LOGOUT: propertyCtaItemsDefaultEmpty,
        MY_ACCOUNT_LOGOUT_HEADER: propertyTextDefaultEmpty,
        MY_ACCOUNT_LOGOUT_CONTENT: propertyTextDefaultEmpty,
        MY_ACCOUNT_BTN_SURE: propertyCtaItemsDefaultEmpty,
        MY_ACCOUNT_BTN_CANCEL: propertyCtaItemsDefaultEmpty,

        MY_ACCOUNT_CLOSE_TITLE: propertyTextDefaultEmpty,
        MY_ACCOUNT_CLOSE_EMAIL: propertyTextDefaultEmpty,
        MY_ACCOUNT_CLOSE_CONTENT: propertyTextDefaultEmptyParsed,

        MY_ACCOUNT_MKT_EMAILS_TITLE: propertyTextDefaultEmpty,
        MY_ACCOUNT_PP_LINK: propertyTextDefaultEmptyParsed,
        MY_ACCOUNT_MKT_INFO_CONTENT: propertyTextDefaultEmptyParsed,
        NOTIF_CHECK_SMS: propertyTextDefaultEmpty,
        NOTIF_CHECK_EMAIL: propertyTextDefaultEmpty,
      }),
      ...aem.getDictionary(aemPages.NOTIFICATION_PREFERENCES_MENU, {
        NOTIF_MARKETING_LABEL: propertyTextDefaultEmpty,
        NOTIF_MARKETING_DISCLAIMER: propertyTextDefaultEmptyParsed
      })
    })

    loadingPreferences();
  }, []);

  const loadingPreferences = () => {
    const availableOptions = Notifications.getAvailableNotificationsPrefsForCurrentOS();

    const currentPreferences = availableOptions.map(option => {
      return { type: option.command, data: Commons.getCustomerProperty(option.command) ?? flagStatus.REFUSED };
    })

    setThings(currentPreferences)
}

  useEffect(() => {
    if (isSupportedService(servicesConstants.SHOP_OTP)) {
      getOneTimeToken();
    }
  }, [getOneTimeToken]);

  useEffect(() => {
    const prevData = getUserData();
    const userId = prevData?.id;

    if (userId) {
      getUserInfo(
        userId,
        (res) => {
          const data = res?.user || {};

          setUser({ ...prevData, ...data });

          const prefs = {
            emailSubscriptionVype: !!data?.emailSubscriptionVype,
            userOptedInForEmails: !!data?.userOptedInForEmails,
            //userOptedInForPushNotifications: !!data?.userOptedInForPushNotifications,
            userOptedInForSMS: !!data?.userOptedInForSMS
          }
          setCrmPreferences(prefs)
        },
        () => {
          setSpinnerError(true)
        }
      );
    }
  }, [getUserInfo]);

  useEffect(() => {

    const handleFocusChange = () => {
      if (document.visibilityState == "visible") {
        const prevData = getUserData();
        const userId = prevData?.id;

        getUserInfo(userId, (res) => {});
      }
    }
    
    window.document.addEventListener("visibilitychange", handleFocusChange);

    return () => {
      window.removeEventListener("visibilitychange", handleFocusChange);
    };

  }, []);

  useEffect(() => {
    // if(ThingsHelper.areAcceptedAnyPushNotificationThings(things, crmPreferences)){
    //   setNeedToAskUserForNotificationPermission(true)
    // } else {
    //   // if the old preferences has some push notifications accepted it means the token has been pushed to the server
    //   // remove it from the server
    //   if(ThingsHelper.areAcceptedAnyPushNotificationThings(things, crmPreferences)){
    //     BrowserHelper.removePWATokenFromIoTPlatform();
    //   }

    //   savePreference()
    // }
    savePreference()

  }, [crmPreferences])

  const setPreference = (preference, value) => {
    setToggleUpdated((prev) => { return preference});
    setCrmPreferences({...crmPreferences, [preference]: value});
  }

  const savePreference = (needResponse = false) => {
    if (!isLoading) {
      setSpinnerLoading(true)
    }

    setDoneNotificationPreferences(true);

    // logAnalyticsEvent(AnalyticsEvents.EMAIL, {
    //    status: getAnalyticStatusBy(crmPreferences.userOptedInForEmails)
    // }) // --> old

    // logAnalyticsEvent(AnalyticsEvents.IN_APP, {
    //    status: getAnalyticStatusBy(crmPreferences.userOptedInForPushNotifications)
    // }) --> old

    const saveAsync = setUserPreferencesAsync(crmPreferences).then(() => {
      let arrPromise = [];
      if("userOptedInForEmails" == toggleUpdated){
        arrPromise.push(new Promise((resolve, reject) =>
          store.dispatch(userActions.setUserSettings(
            crmUserSettings.OPT_IN_EMAIL,
            crmPreferences.userOptedInForEmails,
            resolve,
            reject
          ))
        ));
      }

      if("userOptedInForSMS" == toggleUpdated){
        arrPromise.push(new Promise((resolve, reject) =>
          store.dispatch(userActions.setUserSettings(
            crmUserSettings.OPT_IN_SMS,
            crmPreferences.userOptedInForSMS,
            resolve,
            reject
          ))
        ))
      }

      Promise.all(arrPromise)
      .then(() => {
        setSpinnerLoading(false)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e);
        setSpinnerError(true)
      })
    })
    .catch((e) => {
      console.log(e);
      setSpinnerError(true)
    })

    if (needResponse) {
      return saveAsync
    }
  }

  const resetAndSavePreferences = () => {
    setPreference('userOptedInForPushNotifications', false)
    return savePreference(true)
  }

  return (
    <div className={cx("page w-100 manage-account", {'add-home-bottom': !header})}>
      {header && (
        <Header
          rightButton={{
            icon: <span className="bat-icon-close" />,
            onClick: handleClose,
          }}
        >
          {dictionary.MY_ACCOUNT_HEADER}
        </Header>
      )}
      <div className="page-wrapper" style={{overflow: 'auto'}}>
        <div className="manage-account-details">
          <UserAvatar data={user} />
          <div className="manage-account-name">{`${user?.firstName || ""} ${
            user?.lastName || ""
          }`}</div>
          <div className="manage-account-email">{user?.email || ""}</div>
          {!isSupportedService(servicesConstants.ACCOUNT_PAGE_DISABLED) ?
            <PageSelectorCTA sticky={false}>
              <div className="d-grid">
                <button
                  className="btn btn-primary btn-icon text-uppercase"
                  onClick={handleEditDetails}
                >
                  {dictionary.MY_ACCOUNT_BTN_EDIT_DETAILS_0_ctaLabel}
                  <span className="bat-icon-external-link" />
                </button>
              </div>
            </PageSelectorCTA>
          : null }
        </div>
        {isSupportedService(servicesConstants.EM_DATA_DOWNLOAD) && (
          <div className="manage-account-section">
            <div className="manage-account-section-title">
              {dictionary.MY_ACCOUNT_DOWNLOAD_DATA_TITLE}
            </div>
            <div className="manage-account-section-content">
              {dictionary.MY_ACCOUNT_DOWNLOAD_DATA_CONTENT}
            </div>
            <PageSelectorCTA sticky={false}>
              <div className="d-grid">
                <button
                  className="btn btn-primary btn-icon text-uppercase"
                  onClick={handleDownloadData}
                >
                  {dictionary.MY_ACCOUNT_BTN_DOWNLOAD_DATA_0_ctaLabel}
                  <span className="bat-icon-external-link" />
                </button>
              </div>
            </PageSelectorCTA>
          </div>
        )}

        {isSupportedService(servicesConstants.EM_ACCOUNT_DELETION) ? (
          <div className="manage-account-section">
            <div className="manage-account-section-title">
              {dictionary.MY_ACCOUNT_DELETE_ACCOUNT_TITLE}
            </div>
            <div className="manage-account-section-content">
              {dictionary.MY_ACCOUNT_DELETE_ACCOUNT_CONTENT}
            </div>
            <PageSelectorCTA sticky={false}>
              <div className="d-grid">
                <button
                  className="btn btn-primary btn-icon text-uppercase"
                  onClick={handleDeleteAccount}
                >
                  {dictionary.MY_ACCOUNT_BTN_DELETE_ACCOUNT_0_ctaLabel}
                  <span className="bat-icon-external-link" />
                </button>
              </div>
            </PageSelectorCTA>
          </div>
        ) : (!isSupportedService(servicesConstants.ACCOUNT_PAGE_DISABLED)) ? (
          <div className="manage-account-section">
            <div className="manage-account-section-title">
              {dictionary.MY_ACCOUNT_CLOSE_TITLE}
            </div>
            <div className="manage-account-section-content">
              {dictionary.MY_ACCOUNT_CLOSE_CONTENT}
            </div>
            <div style={{ padding: "1rem 1.6875rem 0.875rem 1.6875rem"}}>
              <button onClick={handleMailSupport} className="btn text-uppercase" style={{ width: "100%", border: "1px solid red", backgroundColor: "black"}}>
                {dictionary.MY_ACCOUNT_CLOSE_EMAIL}
              </button>
            </div>
          </div>
        ) : null }

        {
          !Tenants.isCanadaDark() &&

          <div className="marketing-notification-preferences no-bg">
            {Tenants.isSouthAfrica() &&
              <>
              <div className="notification-preferences-label d-block mb-5">
                {dictionary.NOTIF_MARKETING_LABEL}
              </div>
              <div className="marketing-content">
                {dictionary.NOTIF_MARKETING_DISCLAIMER}
              </div>
              </>
            }
            <div>
              {
                crmPreferences.emailSubscriptionVype ?
                  <div className="switch-container">
                    <label className="form-check-label" htmlFor="emailSubscriptionVype">
                      {dictionary.MY_ACCOUNT_MKT_EMAILS_TITLE}
                    </label>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                            type="checkbox"
                            checked={crmPreferences?.emailSubscriptionVype}
                            value={crmPreferences?.emailSubscriptionVype || ''}
                            id="emailSubscriptionVype"
                            name="emailSubscriptionVype"
                            onChange={(event) => setPreference('emailSubscriptionVype', event.target.checked)} />
                        <label className="form-check-label" htmlFor="emailSubscriptionVype"></label>
                    </div>
                  </div>
                  :
                  <div className={cx("switch-container", {'no-margin-top': !Tenants.isGlobal()})}>
                    <label className="form-check-label" htmlFor="userOptedInForEmails">
                      {/* If there is both toggles, then show just "EMAIL", otherwise "markting emails" */}
                      {!isSupportedSms ? dictionary.MY_ACCOUNT_MKT_EMAILS_TITLE : dictionary.NOTIF_CHECK_EMAIL}
                    </label>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                            type="checkbox"
                            checked={crmPreferences?.userOptedInForEmails}
                            value={crmPreferences?.userOptedInForEmails || ''}
                            id="userOptedInForEmails"
                            name="userOptedInForEmails"
                            onChange={(event) => {
                              logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                                field_name: AnalyticsFieldNames.MARKETING_EMAILS,
                                field_value: event.target.checked
                              });
                              setPreference('userOptedInForEmails', event.target.checked);
                            }} />
                        <label className="form-check-label" htmlFor="userOptedInForEmails"></label>
                    </div>
                  </div>
              }
              {isSupportedSms &&
                  <div className={cx("switch-container", {'no-margin-top': !Tenants.isGlobal()})}>
                    <label className="form-check-label" htmlFor="userOptedInForSMS">
                      {dictionary.NOTIF_CHECK_SMS}
                    </label>
                  <div className="form-check form-switch">
                      <input className="form-check-input"
                          type="checkbox"
                          checked={crmPreferences?.userOptedInForSMS}
                          value={crmPreferences?.userOptedInForSMS || ''}
                          id="userOptedInForSMS"
                          name="userOptedInForSMS"
                          onChange={(event) => {
                            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                              field_name: AnalyticsFieldNames.MARKETING_SMS,
                              field_value: (getAnalyticStatusBy(crmPreferences.userOptedInForEmails)==="enabled") ? true : false
                            });
                            setPreference('userOptedInForSMS', event.target.checked);
                          }} />
                      <label className="form-check-label" htmlFor="userOptedInForSMS"></label>
                  </div>
                </div>
            }
            </div>
            <div>
              <div className="marketing-content" onClick={() => history.replace(buildURI(routingConstants.CONTACT) + convertObjectToQueryString({ canHandleBack: true }), {type: 'slide', backTo: routingConstants.MANAGE_ACCOUNT})}>
                {dictionary.MY_ACCOUNT_MKT_INFO_CONTENT}
              </div>
              <div className="marketing-content footer" onClick={() => history.replace(buildURI(routingConstants.PRIVACY_POLICY) + convertObjectToQueryString({ canHandleBack: true }), {type: 'slide', backTo: routingConstants.MANAGE_ACCOUNT})}>
                {dictionary.MY_ACCOUNT_PP_LINK}
              </div>
            </div>
          </div>
        }

        <PageSelectorCTA sticky={true}>
          <div className="d-grid">
            <button
              className="btn btn-outline-secondary text-uppercase"
              onClick={() => setIsLogoutPanelOpen(true)}
            >
              {dictionary.MY_ACCOUNT_BTN_LOGOUT_0_ctaLabel}
            </button>
          </div>
        </PageSelectorCTA>

      </div>
      <ContactPanel isOpen={isOpenContactPanel} onClose={() => setIsOpenContactPanel(false)} />
      <NotificationPermissionHandler
          needToAskUserForNotificationPermission={needToAskUserForNotificationPermission}
          afterAskingTheUserForNotificationPermissionCallback={() => setNeedToAskUserForNotificationPermission(false)}
          askAfterUserInteraction={true}
          updateAndSavePreferences={() => savePreference(true)}
          resetAndSavePreferences={() => resetAndSavePreferences()}
      />

      {/*isLoading && <CustomLoaderOverlay />*/}
      <CustomSlidingPanel
        from="bottom"
        isOpen={isLogoutPanelOpen}
        overlayClassName="sliding-common-wrapper priority-high"
        className="onboarding-panel mid-height-panel"
        title={dictionary.MY_ACCOUNT_LOGOUT_HEADER}
      >
        <div className="h-100 d-flex flex-column">
          <div className="sliding-panel-mid">
            <div className="sliding-panel-mid-info">
              {dictionary.MY_ACCOUNT_LOGOUT_CONTENT}
            </div>
          </div>
          <PageSelectorCTA sticky={false} className="mt-auto">
            <div className="d-grid gap-2">
              <CustomLoadingButton
                isLoading = {buttonLoading}
                validationMethod = {true}
                buttonLabel = {dictionary.MY_ACCOUNT_BTN_SURE_0_ctaLabel}
                onClickMethod = {handleLogout}
                buttonClassName = {"btn btn-primary text-uppercase"}
              />
              <button
                className="btn btn btn-outline-secondary text-uppercase"
                onClick={() => setIsLogoutPanelOpen(false)}
              >
                {dictionary.MY_ACCOUNT_BTN_CANCEL_0_ctaLabel}
              </button>
            </div>
          </PageSelectorCTA>
        </div>
      </CustomSlidingPanel>
      <div className="page-linear-gradient-bg" />

      <SpinnerModal
        show={spinnerLoading || isLoading}
        error={spinnerError}
        onForceClose={() => {
          const tokens = AxiosCancelTokensHolder.get()

          tokens.forEach(token => {
              token.abort()
          })
        }}
        goOnMoreMenu={ header }
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUserInfo(userId, successCb, failureCb) {
    dispatch(userActions.getUserInfo(userId, successCb, failureCb));
  },
  setUserPreferencesAsync: (data) => {
    return new Promise((resolve, reject) => {
      dispatch(userActions.updateUserInfo(data, resolve, reject))
    })
  }
});

const connectedComponent = connect(null, mapDispatchToProps)(ManageAccount);

export { connectedComponent as ManageAccount };
