import FaceCapture from "@getyoti/react-face-capture";
import { isNil } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRegistration, setRegistration, userActions } from "../../_actions";
import {
  AnalyticsErrorNames,
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  HARD_AGE_VERIFICATION_STEPS,
  aemPages,
  imagesConstants,
  routingConstants,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import {
  isError,
  isSuccess,
  isTooClose,
  isUnsuccess,
  isFake,
} from "../../_helpers/hard-age-verification";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { estimateAge } from "../../_services";
import { CustomSlidingPanel } from "../CustomSlidingPanel";
import { HardAgeVerificationProcessing } from "./HardAgeVerificationProcessing";
import { PageSelectorCTA } from "../PageSelectorCTA";
import { Tenants } from "../../_helpers/tenants";

function HardAgeVerificationCapture({ goToStep, updateUserInfo, onSubmit }) {
  const [dictionary, setDictionary] = useState({});
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [showYOTI, setShowYOTI] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowYOTI(true);
    }, 1000);
  }, []);

  const onSuccess = (payload) => {
    setImage(payload.img);
    logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_PROCESSING);
  };

  const onError = (err) => {
    console.debug("HARD_AGE_VERIFICATION_FACE_CAPTURE_ERROR", err);
    logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_FAILURE, { error: err });
    goToStep(HARD_AGE_VERIFICATION_STEPS.NEGATE_CONSENT);
  };

  const verify = useCallback(() => {
    const getEstimationResult = async () => {
      try {
        const res = await estimateAge(image);
        const data = res?.data;

        console.log("**********************");
        console.log(data);

        if (isSuccess(data)) {
          console.debug("HARD_AGE_VERIFICATION_SUCCESS");
          logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_SUCCESS);
          setIsComplete(true);

          //show success loader and then navigate
          setTimeout(() => {
            if (Tenants.isGlobal()) {
              updateUserInfo(
                { isUserAgeVerified: true },
                () => {
                  history.push(buildURI(routingConstants.TERMS_AND_CONDITIONS));
                },
                (err) => {
                  console.debug(err);
                }
              );
            } else {
              let registration = getRegistration()
              registration.isValid = true
              registration.verificationMethod = 'AGE_ESTIMATION'
              registration.triedFace = true
              setRegistration(registration)
    
              history.push(buildURI(routingConstants.SIGN_UP))
            }
          }, 3000);
        } else if (isUnsuccess(data)) {
          let registration = getRegistration();
          registration.triedFace = true;
          setRegistration(registration);

          console.debug("HARD_AGE_VERIFICATION_UNSUCCESS");
          logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_PROBLEM, {
            reason: AnalyticsErrorNames.TO_CLOSE_TO_AGE,
          });
          if (Tenants.isGlobal()) {
            goToStep(HARD_AGE_VERIFICATION_STEPS.UNSUCCESS);
          } else {
            goToStep(HARD_AGE_VERIFICATION_STEPS.TOO_CLOSE);
          }
        } else if (isTooClose(data)) {
          let registration = getRegistration();
          registration.triedFace = true;
          setRegistration(registration);

          console.debug("HARD_AGE_VERIFICATION_TOO_CLOSE");
          logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_PROBLEM, {
            reason: AnalyticsErrorNames.TO_CLOSE_TO_AGE,
          });
          goToStep(HARD_AGE_VERIFICATION_STEPS.TOO_CLOSE);
        } else if (isFake(data)) {
          let registration = getRegistration();
          registration.triedFace = true;
          registration.fake = true;
          setRegistration(registration);

          console.debug("HARD_AGE_VERIFICATION_FACE_FAKE");
          logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_PROBLEM, {
            reason: AnalyticsErrorNames.FAKE_PICTURE,
          });
          goToStep(HARD_AGE_VERIFICATION_STEPS.FACE_FAKE);
        } else if (isError(data)) {
          console.debug("HARD_AGE_VERIFICATION_ERROR");
          logAnalyticsEvent(AnalyticsEvents.YOTI_FACE_SCAN_FAILURE, {
            error: data?.error_code,
          });
          setError(data);
        } else {
          console.debug("HARD_AGE_VERIFICATION_OTHER");
          if (Tenants.isGlobal()) {
            goToStep(HARD_AGE_VERIFICATION_STEPS.UNSUCCESS);
          } else {
            goToStep(HARD_AGE_VERIFICATION_STEPS.TOO_CLOSE);
          }
        }
      } catch (err) {
        console.debug("HARD_AGE_VERIFICATION_CATCH");
        if (Tenants.isGlobal()) {
          goToStep(HARD_AGE_VERIFICATION_STEPS.UNSUCCESS);
        } else {
          goToStep(HARD_AGE_VERIFICATION_STEPS.TOO_CLOSE);
        }
      }
    };

    getEstimationResult();
  }, [goToStep, image]);

  const retry = () => {
    setError(null);
    setImage(null);
  };

  const getErrorCause = () => {
    if (error?.error_code) {
      const cause = dictionary[`YOTI_${error?.error_code}`];

      return cause ? cause : dictionary.YOTI_VERIFICATION_FAILED_CONTENT;
    }

    return dictionary.YOTI_VERIFICATION_FAILED_CONTENT;
  };

  const getErrorCauseDesc = () => {
    if (error?.error_code) {
      const cause = dictionary[`YOTI_${error?.error_code}_DES`];

      return cause ? cause : "";
    }

    return "";
  };

  useEffect(() => {
    const aem = new AEMHelper();

    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {
      screen_name: AnalyticsScreenEventNames.YOTI_FACE_SCAN,
    });

    setDictionary(
      aem.getDictionary(aemPages.HARD_AGE_VERIFICATION, {
        YOTI_VERIFYING_AGE: propertyTextDefaultEmpty,
        YOTI_SUCCESSFULLY_VERIFIED: propertyTextDefaultEmpty,
        YOTI_VERIFICATION_FAILED_HEADER: propertyHeadingDefaultEmpty,
        YOTI_VERIFICATION_FAILED_CONTENT: propertyTextDefaultEmpty,
        YOTI_FACE_NOT_FOUND: propertyTextDefaultEmpty,
        YOTI_FACE_NOT_FOUND_DES: propertyTextDefaultEmpty,
        YOTI_MULTIPLE_FACES: propertyTextDefaultEmpty,
        YOTI_MULTIPLE_FACES_DES: propertyTextDefaultEmpty,
        YOTI_FACE_BOX_TOO_SMALL: propertyTextDefaultEmpty,
        YOTI_FACE_BOX_TOO_SMALL_DES: propertyTextDefaultEmpty,
        YOTI_FACE_TO_IMAGE_RATIO_TOO_LOW: propertyTextDefaultEmpty,
        YOTI_FACE_TO_IMAGE_RATIO_TOO_LOW_DES: propertyTextDefaultEmpty,
        YOTI_FACE_TO_IMAGE_RATIO_TOO_HIGH: propertyTextDefaultEmpty,
        YOTI_FACE_TO_IMAGE_RATIO_TOO_HIGH_DES: propertyTextDefaultEmpty,
        YOTI_INSUFFICIENT_AREA_AROUND_THE_FACE: propertyTextDefaultEmpty,
        YOTI_INSUFFICIENT_AREA_AROUND_THE_FACE_DES: propertyTextDefaultEmpty,
        YOTI_IMAGE_TOO_BRIGHT: propertyTextDefaultEmpty,
        YOTI_IMAGE_TOO_BRIGHT_DES: propertyTextDefaultEmpty,
        YOTI_IMAGE_TOO_DARK: propertyTextDefaultEmpty,
        YOTI_IMAGE_TOO_DARK_DES: propertyTextDefaultEmpty,
        YOTI_VERIFICATION_FAILED_ERROR_CODE: propertyTextDefaultEmpty,
        YOTI_VERIFICATION_FAILED_BTN_RETRY: propertyCtaItemsDefaultEmpty,
        YOTI_TAKE_MOMENT: propertyTextDefaultEmpty,
        YOTI_SCAN_LOADING_TEN_SECONDS: propertyTextDefaultEmpty,
      })
    );
  }, []);

  useEffect(() => {
    if (!isNil(image)) {
      verify();
    }
  }, [image, verify]);

  return (
    <>
      {isNil(image) &&
      <div
        style={{
          background: "transparent",
          position: "absolute"
        }}
      >
        <div className="d-grid text-center mt-2">
          <div style={{fontSize: 24, margin: 24, fontWeight: 200}}>
            {dictionary.YOTI_SCAN_LOADING_TEN_SECONDS}{" "}
          </div>
        </div>
      </div>
      }

      <div
        className="page page-linear-gradient-bg w-100"
        style={{
          background: "transparent",
          position: isNil(image) ? "fixed" : "absolute",
        }}
      >
        {isNil(image) ? (
          showYOTI ? (
            <div className="face-capture-wrapper">
              <FaceCapture
                faceCaptureAssetsRootUrl="../../_assets/face-capture"
                captureMethod="auto"
                manualCaptureFallback={false}
                language={Commons.getLang()}
                onSuccess={onSuccess}
                onError={onError}
                secure={false}
                showInitialGuidance={false}
              />
            </div>
          ) : null
        ) : (
          <HardAgeVerificationProcessing
            isComplete={isComplete}
            text={
              isComplete
                ? dictionary.YOTI_SUCCESSFULLY_VERIFIED
                : dictionary.YOTI_VERIFYING_AGE 
            }
            subText={dictionary.YOTI_TAKE_MOMENT}
          />
        )}
        <div className="hard-age-verification-powered-by">
          <img
            src={imagesConstants.HARD_AGE_VERIFICATION_POWERED_BY_YOTI}
            alt="Powered By YOTI"
          />
        </div>
        <CustomSlidingPanel
          isOpen={error}
          backdropClose={false}
          from="bottom"
          overlayClassName="sliding-common-wrapper"
          className="onboarding-panel mid-height-panel"
          title={dictionary.YOTI_VERIFICATION_FAILED_HEADER}
        >
          <div className="sliding-panel-mid">
            <div className="sliding-panel-mid-icon">
              <span
                className="bat-icon-alert-triangle-outline"
                style={{ fontSize: "3.75rem" }}
              />
            </div>
            <div className="sliding-panel-mid-info">{getErrorCause()}</div>
            <div className="sliding-panel-mid-info text-secondary">
              {getErrorCauseDesc()}
            </div>
          </div>
          <PageSelectorCTA sticky={false} className="mt-auto">
            <div className="d-grid gap-2">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={retry}
              >
                {dictionary.YOTI_VERIFICATION_FAILED_BTN_RETRY_0_ctaLabel}
              </button>
              <div className="d-grid text-center mt-2">
                <small>
                  {dictionary.YOTI_VERIFICATION_FAILED_ERROR_CODE}{" "}
                  {error?.error_code || ""}
                </small>
              </div>
            </div>
          </PageSelectorCTA>
        </CustomSlidingPanel>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (data, successCb, failureCb) =>
    dispatch(userActions.updateUserInfo(data, successCb, failureCb)),
});

const connectedComponent = connect(
  null,
  mapDispatchToProps
)(HardAgeVerificationCapture);
export { connectedComponent as HardAgeVerificationCapture };
