import React, {forwardRef, useEffect, useState, useRef} from "react";
import { imagesConstants } from '../../_constants';
import { Utils } from "../../_helpers/graphic/utils";
import cx from "classnames";

export const UltraPodWidget = forwardRef(({
    flavour,
    device,
    top,
    updateHeight,
    connectionStatusLabel,
    intensityLabel,
    nicotineLabel,
    absolute = true,
    autoTune = false,
    addClass = null,
    onAutoTuneClick = () => {},
    isWattageRecommended = false,
    isAutotuneActive = false,
    setRecommendedPower = () => {}
}, ref) => {

    const [isWidgetExpanded, setIsWidgetExpanded] = useState(false);
    const [textColor, setTextColor] = useState("#FFFFFF");
    const recommendedIntensity = useRef((Math.round(
        (device?.liquidInfo.recommendedHeatingPower/10) // divides value by 10
        *10)/10).toFixed(1) // rounds at the first decimal digit
    )

    const toggleWidget = () => {
        setIsWidgetExpanded(!isWidgetExpanded);
    }

    const getRoundedLiquidLevel = (liquidLevel) => {
        return Math.ceil(liquidLevel / 10) * 10;
    }

    useEffect(() => {
        
        setTextColor(Utils.getTextColorFromBackground(flavour?.color));

    }, []);

    useEffect(() => {
        updateHeight(isWidgetExpanded);
    }, [isWidgetExpanded]);

    return (
        <div ref={ref} className={cx('ultra-pod-widget', addClass, {'absolute': absolute})} style={{top: top}}>
            <div className="bar" style=
                {(!autoTune || isWattageRecommended)
                ? {backgroundColor: flavour?.color}
                : {}}
            >

                {/* pod connection status */}

                {/*<div className="ultra-pod-status">
                    <div className="status-loader"></div>
                    <div className="status-title">{connectionStatusLabel}</div>
                </div>*/}

                {/* widget collapsed */}

                {/*console.log("device info", device)*/}

                <div className="info-container flavour-icon">
                    <img src={flavour && flavour.icon} />
                </div>
                <div className="flavour-name" style=
                    {(autoTune && !isWattageRecommended)
                    ? {color: "#ffffff"}
                    : {color: textColor}}
                >
                    {flavour?.name}</div>
                {autoTune &&
                <div className="info-container auto-tune-icon" onClick={onAutoTuneClick}>
                    <img src={imagesConstants.CLOUD_CONTROL_SETTINGS} />
                </div>
                }
                {!autoTune && 
                <>
                <div className="info-container expand-icon" onClick={toggleWidget}>
                    <img
                        src={imagesConstants.WIDGET_ARROW}
                        style={{transform: isWidgetExpanded ? 'rotate(180deg)' : ''}}
                    />
                </div>
                <div className="info-container liquid-info">
                    <img src={Utils.getDropIconFromLiquidLevel(device?.liquidRemaining)} />
                    <div className="liquid-percentage">{getRoundedLiquidLevel(device?.liquidRemaining)}<span>%</span></div>
                </div>
                </>
                }
                <div className="space-cleaner"></div>

                {!autoTune &&
                    <div className={cx("detailed-info", {'showing': isWidgetExpanded})}>
                        <div className="detailed-info-row info-title">
                            <div>{intensityLabel}</div>
                            <div>{nicotineLabel}</div>
                        </div>
                        <div className="detailed-info-row info-value">
                            <div style={
                                (isWattageRecommended || isAutotuneActive) ? 
                                {
                                    color: "#FFFFFF",
                                    backgroundImage: `radial-gradient(75.06% 100.81% at 63.36% 121.43%, ${flavour?.color} 0%, ${flavour?.gradient_color} 100%)`,
                                } :
                                {
                                    color: "#AAAAAA",
                                    backgroundImage: "none",
                                    backgroundColor: "#2A2A2A"
                                }
                            }>
                                {recommendedIntensity.current}W
                            </div>
                            <div>{device?.liquidInfo.nicotineStrength}mg/mL</div>
                        </div>
                    </div>
                }

                {autoTune &&
                    <div className="detailed-info showing-small">
                        <div className="detailed-info-row info-value info-title">
                            <div className="recommended-label">
                                {intensityLabel}
                            </div>
                            <div className="recommended-value" onClick={() => setRecommendedPower()}>
                                {recommendedIntensity.current}W
                            </div>
                        </div>
                    </div>
                }
            
            </div>
        </div>
    );

});